<ul  class="no-style-list" >
<li
  ngbDropdown
  class="nav-item dropdown-chat mr-25"
>
  <a
    class="nav-link"
    ngbDropdownToggle
    id="navbarhistoryDropdown"
    ><i
      class="ficon"
      data-feather="clock"
    ></i
    ><span class="badge badge-pill badge-primary badge-up">5</span></a
  >
</li>
</ul>