import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { IFilters } from "app/interfaces/controls/IFiltersConfig";
import { FlatpickrOptions } from "ng2-flatpickr";
import { FlatpickrInstance } from "ng2-flatpickr/src/flatpickr-instance";

@Component({
  selector: "app-to-date-picker",
  templateUrl: "./to-date-picker.component.html",
})
export class ToDatePickerComponent implements OnInit {
  @Input("filterData") Config: IFilters;
  @Output() changeValue = new EventEmitter();
  @ViewChild("ToDate") ToDate: FlatpickrInstance;

  public ToDateOptions: FlatpickrOptions = {
    altInput: true,
    weekNumbers: true,
    locale: {
      firstDayOfWeek: 1, // start week on Monday
    },
  };
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.cd.detectChanges();
  }
  onchange() {
    this.changeValue.emit();
  }

  clear() {
    (<any>this.ToDate).flatpickr.clear();
    this.Config.filterValue = null;
  }
}
