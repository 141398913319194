import { Pipe, PipeTransform } from "@angular/core";

let StringMask = require("string-mask");
@Pipe({ name: "PhoneMask" })
export class PhoneMask implements PipeTransform {
  transform(value: string) {
    let formatter = new StringMask("(000) 000-0000");
    let result = formatter.apply(value);
    return result;
  }
}