import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IIncidentReportDialogConfig } from "app/interfaces/controls/dialogs/IIncidentReportDialogConfig";

@Component({
  selector: "app-incident-report-dialog",
  templateUrl: "./incident-report-dialog.component.html",
})
export class IncidentReportDialogComponent {
  constructor(private modalService: NgbModal, private router: Router) {}
  @Input() dialogconfig: IIncidentReportDialogConfig;
  @ViewChild("modalLG")
  private modalRef: TemplateRef<any>;
  public Uuid: TemplateRef<any>;

  modalOpen(Uuid) {
    this.Uuid = Uuid;
    this.modalService.open(this.modalRef, {
      centered: true,
      size: "lg",
    });
  }

  openIncidentReportPrint() {
    event.preventDefault();
    this.modalService.dismissAll("Accept click");
    this.router.navigate([`/Performance/IncidentReportPrint/${this.Uuid}`]);
  }
}
