import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "formatDurationInMinutes" })
export class FormatDurationInMinutes implements PipeTransform {
  transform(value: string) {
    let durationFormattedText = "";
    if (parseInt(value) < 60) {
      if (parseInt(value) < 9) {
        durationFormattedText = value + " min";
      } else {
        durationFormattedText = value + " mins";
      }
    } else {
      let durationInHours = parseInt((parseInt(value) / 60).toString());
      let remainingInMinutes = parseInt(value) - parseInt(value) * 60;
      durationFormattedText =
        durationInHours +
        "h " +
        (remainingInMinutes > 0 ? remainingInMinutes + "m" : "");
    }
    return durationFormattedText;
  }
}
