import { Component, Input } from "@angular/core";
import { IFilters } from "app/interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-monthly-periods",
  templateUrl: "./monthly-periods.component.html",
})
export class MonthlyPeriodsComponent {
  @Input("filterData") Config: IFilters;
}
