<div
  id="actionBar"
  class="d-flex align-items-end bg-white no-print"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-center">
      <div class="flex-fill">
        <button
          type="button"
          class="btn btn-info btn-sm"
          (click)="EditBoard()"
          rippleEffect
        >
          {{ isMoveEnable ? "SAVE" : "EDIT BOARD" }}
        </button>
      </div>
      <div class="flex-fill text-right">
        <div class="btn-group">
          <div
            ngbDropdown
            [placement]="'bottom-right'"
          >
            <button
              ngbDropdownToggle
              class="btn btn-info btn-page-action"
              type="button"
              id="dropdownMenuButton"
              rippleEffect
            >
              <span [data-feather]="'menu'"></span>
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownMenuButton"
            >
              <a
                ngbDropdownItem
                href="javascript:window.print();"
              >
                <span [data-feather]="'printer'"></span> Print</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  id="SubactionBar"
  class="bg-white d-flex align-items-center bg-white no-print"
>
  <div class="container-fluid">
    <button
      type="button"
      class="btn btn-link pl-0 pr-0 p-0"
      style="color: #b8c2cc !important"
      disabled
      rippleEffect
    >
      Reset Filters
    </button>
  </div>
</div>

<div class="content-wrapper container-fluid">
  <div class="content-body">
    <div class="row">
      <div class="col-lg-6 no-print">
        <h3 class="text-primary dashboard-title">FINANCIAL OVERVIEW</h3>
      </div>
      <div class="col-lg-6 text-right">
        <h6 class="d-inline pr-1 dashboard-Filter-title">FISCAL YEAR</h6>

        <button
          *ngFor="let item of listOfYears"
          type="button"
          class="btn btn-sm"
          [class]="item.Class"
          (click)="changeYear(item.CurrentYear)"
          rippleEffect
        >
          {{ item.FirstYear }}-{{ item.SecondYear }}
        </button>
      </div>
    </div>
    <section id="home-page">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card widget-card h-100">
            <div class="card-body">
              <div
                class="row"
                dragula="card-drag-area"
                id="draggable-wedgit"
              >
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-primary">
                      <h4 class="text-secondary">REVENUE</h4>
                      <h3 class="text-primary">$ {{ REVENUE }}</h3>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-warning">
                      <h4 class="text-secondary">PROFIT</h4>
                      <h3 class="text-primary">$ {{ PROFIT }}</h3>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-succes">
                      <h4 class="text-secondary">INVOICES</h4>
                      <h3 class="text-primary">$ {{ INVOICES }}</h3>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-light">
                      <h4 class="text-secondary">GROSS MARGIN BPAC</h4>
                      <h3 class="text-primary">$ {{ GROSSMARGINBPAC }}</h3>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-danger">
                      <h4 class="text-secondary">GROSS MARGIN APAC</h4>
                      <h3 class="text-primary">$ {{ GROSSMARGINAPAC }}</h3>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-secondary">
                      <h4 class="text-secondary">CONTRACTORS COMP</h4>
                      <h3 class="text-primary">$ {{ CONTRACTORSCOMP }}</h3>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row"
        dragula="card-drag-area"
        id="draggable-cards"
      >
        <div class="col-lg-6 draggable">
          <div class="card {{ isMoveEnable ? 'MoveEnabled' : '' }}">
            <div class="card-header">
              <div
                class="moveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>

              <h4 class="card-title text-primary">Annual Revenue Overview</h4>
              <h6 class="card-title">REVENUE BY MONTH</h6>
            </div>
            <div class="card-body pb-1 text-center">
              <iframe
                style="border: none"
                title=""
                seamless
                height="640px"
                width="100%"
                src="https://bi.goadroit.com/embed/query/4/visualization/6?api_key=r4DePL22XmhTJDpeTbOtL3enJu9ZfT4z0gTLi7Zo&p_year=2022&p_Months=All%20Months&hide_link&hide_header"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="col-lg-6 draggable">
          <div class="card {{ isMoveEnable ? 'MoveEnabled' : '' }}">
            <div class="card-header">
              <div
                class="moveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>
              <h4 class="card-title text-primary">Client Activity Overview</h4>
              <h6 class="card-title">AVERAGE BY DAY</h6>
            </div>
            <div class="card-body pb-1 text-center">
              <iframe
                style="border: none"
                title=""
                width="100%"
                height="640px"
                seamless
                id="client_iframe"
                src="https://bi.goadroit.com/embed/query/7/visualization/19?api_key=dIOeCxCbzTueGY9jQRzI8aXHAKaaCE2RoEeCQ4rw&p_Date%20Range=d_last_month&p_Clients=All%20Clients&hide_link&hide_header"
              >
              </iframe>
            </div>
          </div>
        </div>

        <div class="col-lg-6 draggable">
          <div class="card {{ isMoveEnable ? 'MoveEnabled' : '' }}">
            <div class="card-header">
              <div
                class="moveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>
              <h4 class="card-title text-primary">
                Contractors Payout Overview
              </h4>
              <h6 class="card-title">REVENUE BY MONTH</h6>
            </div>
            <div class="card-body pb-1 text-center">
              <iframe
                style="border: none"
                title=""
                width="100%"
                height="640px"
                seamless
                id="contractors_chart_iframe"
                src="https://bi.goadroit.com/embed/query/8/visualization/13?api_key=A4MnV3sfvtvMX3xPqnwlsrqrMKwoQGdjjtivRGs6&p_Date%20range=d_last_month&p_Contractors=All%20Contractors&hide_link&hide_header"
              >
              </iframe>
            </div>
          </div>
        </div>
        <div class="col-lg-6 draggable">
          <div class="card {{ isMoveEnable ? 'MoveEnabled' : '' }}">
            <div class="card-header">
              <div
                class="moveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>
              <h4 class="card-title text-primary">
                Service Area Revenue Share
              </h4>
              <h6 class="card-title">AVERAGE BY WEEK</h6>
            </div>
            <div class="card-body pb-1 text-center">
              <iframe
                style="border: none"
                title=""
                seamless
                height="640px"
                width="100%"
                src="https://bi.goadroit.com/embed/query/2/visualization/16?api_key=W6IcXXXlknEV93NuPqyGvl4SSRRVzSF31pxkNGs2&p_Date%20Range=d_last_month&p_ServiceArea=All%20Service%20Areas&hide_link&hide_header"
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
