<div
  id="actionBar"
  class="d-flex align-items-end bg-white no-print"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-center">
      <div class="flex-fill page-title">
        <h3 class="text-primary m-0">Financial Overview</h3>
      </div>
      <div class="flex-fill text-right">
        <div class="btn-group">
          <div
            ngbDropdown
            [placement]="'bottom-right'"
          >
            <button
              ngbDropdownToggle
              class="btn btn-info btn-page-action"
              type="button"
              id="dropdownMenuButton"
              rippleEffect
            >
              <span [data-feather]="'menu'"></span>
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownMenuButton"
            >
              <a
                ngbDropdownItem
                href="javascript:window.print();"
              >
                <span [data-feather]="'printer'"></span> Print</a
              >
              <a
                ngbDropdownItem
                href="javascript:void(0)"
                (click)="exportToExcel()"
              >
                <span [data-feather]="'download'"></span> Download Xlsx</a
              >
              <div *ngIf="needExportToCsv">
                <a
                  ngbDropdownItem
                  href="javascript:void(0)"
                  (click)="exportToCsv()"
                >
                  <span [data-feather]="'download'"></span> Download Csv</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="SubactionBar"
  class="bg-white no-print"
>
  <div class="container-fluid">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
  </div>
</div>
