export enum FiltersTypes {
  /**
   * year component
   */
  year = "Year",
  /**
   * monthly periods component
   */
  monthlyPeriods = "MonthlyPeriods",
  /**
   * state component
   */
  state = "State",
  /**
   * contractors component
   */
  contractors = "Contractors",

  /**
   * fiscal period
   */
  fiscalPeriod = "FiscalPeriod",

  /**
   * clients
   */
  clients = "clients",

  /**
   * From Date Picker
   */
  fromDatePicker = "FromDatePicker",

  /**
   * to Date Picker
   */
  toDatePicker = "ToDatePicker",

  /**
   * Search by Text
   */
  searchByText = "searchByText",

  /**
   * Day Periods  Morning Or Evening
   */
  DayPeriods = "DayPeriods",

  /**
   * Statues
   */
  Statues = "Statues",

  /**
   * service providers
   */
  serviceproviders = "serviceproviders",

  /**
   * userstypes
   */
  userstypes = "userstypes",
  /**
   * Passengers
   */
  Passengers = "Passengers",
  /**
   * weekly periods
   */
  weeklyperiods = "weeklyperiods",
}
