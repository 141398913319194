<div class="app-content">
  <div class="content-wrapper p-0">
    <div class="content-body">
      <!-- content-header component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>

      <!-- Basic Alerts start -->
      <section id="home-page">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header"></div>
              <div class="card-body pb-1">
                <p class="card-text">All the best for your new project.</p>
                <p class="card-text">
                  Please make sure to read our
                  <a
                    href="https://pixinvent.com/demo/vuexy-angular-admin-dashboard-template/documentation/"
                    target="_blank" rel="noopener"
                    >Template Documentation</a
                  >
                  to understand where to go from here and how to use our
                  template.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Basic Alerts end -->
    </div>
  </div>
</div>
