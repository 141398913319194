import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "Titlevalue" })
export class Titlevalue implements PipeTransform {
  transform(value: string) {
    value = value.replace(/_/g, " ");
    return value.replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }
}
