import { Injectable } from "@angular/core";

import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class ExcelServiceService {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {}
  exportExcel(excelData) {
    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers;
    const data = excelData.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Sheet1");

    //Add Row and formatting

    if (excelData.title != null) {
      let titleRow = worksheet.addRow([title]);
      titleRow.getCell(1).font = {
        size: 12,
        bold: true,
        color: { argb: "000000" },
      };
      titleRow.alignment = { vertical: "middle", horizontal: "center" };
      worksheet.mergeCells(
        `$A${titleRow.number}:${excelData.titlelength}${titleRow.number}`,
      );
    }

    if (excelData.subtitle != null) {
      let subtitle = worksheet.addRow([excelData.subtitle]);
      subtitle.getCell(1).font = {
        size: 12,
        bold: true,
        color: { argb: "000000" },
      };
      subtitle.alignment = { vertical: "middle", horizontal: "center" };
      worksheet.mergeCells(
        `$A${subtitle.number}:${excelData.subtitlelength}${subtitle.number}`,
      );
    }

    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.font = {
        size: 12,
        bold: true,
      };
      if (
        excelData.headerscolors != null &&
        excelData.headerscolors.length > 0
      ) {
        let filter = excelData.headerscolors.filter(
          x => x.cellname == cell.value,
        );
        if (filter.length > 0)
          cell.fill = {
            pattern: "solid",
            type: "pattern",
            fgColor: { argb: filter[0].color }, // colors must be without #
          };
      }
    });
    // Adding Data with Conditional Formatting
    data.forEach(d => {
      worksheet.addRow(d);
    });

    worksheet.columns = [
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];
    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, excelData.filename + (excelData.extention ?? ".xlsx"));
    });
  }
  getGoogleAPIKey() {
    let propertyName = "GoogleAPIKeyBI";
    return this._httpClient.get<any>(
      `${environment.apiUrl}configurations/${propertyName}/name`,
    );
  }
  exportCSV(excelData) {
    let header = excelData.headers.join(",");
    let rows = excelData.data.map(row => row.join(",")).join("\n");
    let csvContent = header + "\n" + rows;
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    fs.saveAs(blob, excelData.filename + ".csv");
  }
  SavePDF(pdfContent: string, fileName: string): void {
    // Create your content

    // Create a Blob from the HTML content
    const blob = new Blob([pdfContent], { type: "application/pdf" });

    // Generate a file name

    // Trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    // Clean up
    URL.revokeObjectURL(link.href);
  }
}
