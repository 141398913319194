import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable()
export class RideSummaryService {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {}

  GetRideSummaryData(RideUuid) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}rides/${RideUuid}/sheet-details`,
    );
  }
}
