import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-report-header",
  templateUrl: "./report-header.component.html",
})
export class ReportHeaderComponent {
  @Input() public contentHeader: object;
  @Input() public needExportToCsv: false;
  @Output() exportToExcelEvent = new EventEmitter();
  exportToExcel() {
    this.exportToExcelEvent.emit();
  }
  @Output() exportToCsvEvent = new EventEmitter();
  exportToCsv() {
    this.exportToCsvEvent.emit();
  }
}
