import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable()
export class ProfileService {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {}

  GetContractorData(contractorid) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}partners/${contractorid}/profile`,
    );
  }
  GetServiceProviderData(serviceProviderId) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}organizations/${serviceProviderId}/profile`,
    );
  }
}
