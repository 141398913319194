import { NgModule } from "@angular/core";
import { BankNumbersMask } from "./BankNumbersMask";
import { FormatDurationInMinutes } from "./formatDurationInMinutes";
import { PhoneMask } from "./PhoneMask";
import { PoNumber } from "./poNumber";
import { SSNMask } from "./SSNMask";
import { TinMask } from "./TinMask";
import { Titlevalue } from "./Titlevalue";

@NgModule({
  declarations: [
    PoNumber,
    PhoneMask,
    SSNMask,
    Titlevalue,
    TinMask,
    BankNumbersMask,
    FormatDurationInMinutes,
  ],
  imports: [],
  exports: [
    PoNumber,
    PhoneMask,
    SSNMask,
    Titlevalue,
    TinMask,
    BankNumbersMask,
    FormatDurationInMinutes,
  ],
})
export class PipesModule {}
