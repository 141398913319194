<div
  id="actionBar"
  class="d-flex align-items-end bg-white no-print"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-center">
      <div class="flex-fill">
        <button
          type="button"
          class="btn btn-info btn-sm"
          (click)="EditBoard()"
          rippleEffect
        >
          {{ isMoveEnable ? "SAVE" : "EDIT BOARD" }}
        </button>
      </div>
      <div class="flex-fill text-right">
        <div class="btn-group">
          <div
            ngbDropdown
            [placement]="'bottom-right'"
          >
            <button
              ngbDropdownToggle
              class="btn btn-info btn-page-action"
              type="button"
              id="dropdownMenuButton"
              rippleEffect
            >
              <span [data-feather]="'menu'"></span>
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownMenuButton"
            >
              <a
                ngbDropdownItem
                href="javascript:window.print();"
              >
                <span [data-feather]="'printer'"></span> Print</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  id="SubactionBar"
  class="bg-white d-flex align-items-center bg-white no-print"
>
  <div class="container-fluid">
    <button
      type="button"
      class="btn btn-link pl-0 pr-0 p-0"
      style="color: #b8c2cc !important"
      disabled
      rippleEffect
    >
      Reset Filters
    </button>
  </div>
</div>

<div class="content-wrapper container-fluid">
  <div class="content-body">
    <div class="row">
      <div class="col-lg-6 no-print">
        <h3 class="text-primary dashboard-title">PERFORMANCE OVERVIEW</h3>
      </div>
      <div class="col-lg-6 text-right dashboard-Filter-title">
        <div class="row g-3 align-items-center float-right">
          <div class="col-auto pb-0">
            <h6 class="d-inline pr-1">PERIOD</h6>
          </div>
          <div class="col-auto pb-0 control-sm">
            <ng2-flatpickr
              [config]="FromDateOptions"
              id="FromDate"
              name="FromDate"
              placeholder="June 18, 2021"
              [(ngModel)]="fromDate"
              [setDate]="fromDate"
              (change)="changedate()"
            ></ng2-flatpickr>
          </div>
          <div class="col-auto pb-0 control-sm">
            <ng2-flatpickr
              [config]="ToDateOptions"
              id="ToDate"
              name="ToDate"
              placeholder="June 18, 2021"
              [(ngModel)]="toDate"
              [setDate]="toDate"
              (change)="changedate()"
            ></ng2-flatpickr>
          </div>
        </div>
      </div>
    </div>
    <section id="home-page">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card widget-card h-100">
            <div class="card-body">
              <div
                class="row"
                dragula="card-drag-area"
                id="draggable-wedgit"
              >
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-primary">
                      <h4 class="text-secondary"># LEADS</h4>
                      <h3 class="text-primary">{{ LEADS }}</h3>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-warning">
                      <h4 class="text-secondary"># CONVERTED DRIVERS</h4>
                      <h3 class="text-primary">{{ CONVERTEDDRIVERS }}</h3>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-succes">
                      <h4 class="text-secondary"># ASSIGNED DRIVERS</h4>
                      <h3 class="text-primary">{{ ASSIGNEDDRIVERS }}</h3>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-light">
                      <h4 class="text-secondary"># NON-COMPLIANT DRIVERS</h4>
                      <h3 class="text-primary">
                        {{ NONCOMPLIANTDRIVERS }}
                        <span
                          class="text-secondary"
                          style="font-weight: 100"
                          >(All Periods)</span
                        >
                      </h3>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-danger">
                      <h4 class="text-secondary"># ACTIVE STUDENTS</h4>
                      <h3 class="text-primary">
                        {{ ACTIVESTUDENTS }}
                        <span
                          class="text-secondary"
                          style="font-weight: 100"
                          >(All Periods)</span
                        >
                      </h3>
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-4 pb-lg-0 draggable">
                  <div class="{{ isMoveEnable ? 'MoveEnabled' : '' }}">
                    <div
                      class="WidgetmoveIcon"
                      *ngIf="isMoveEnable"
                    >
                      <span
                        [data-feather]="'move'"
                        [class]="'mr-25'"
                      ></span>
                    </div>
                    <div class="widget-body widget-secondary">
                      <h4 class="text-secondary"># ACTIVE BLUEPRINTS</h4>
                      <h3 class="text-primary">
                        {{ ACTIVEBLUEPRINTS }}
                        <span
                          class="text-secondary"
                          style="font-weight: 100"
                          >(All Periods)</span
                        >
                      </h3>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row"
        dragula="card-drag-area"
        id="draggable-cards"
      >
        <div class="col-lg-6 draggable">
          <div class="card {{ isMoveEnable ? 'MoveEnabled' : '' }}">
            <div class="card-header">
              <div
                class="moveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>
              <h4 class="card-title text-primary">Serviced Rides</h4>
              <h6 class="card-title">REVENUE BY MONTH</h6>
            </div>
            <div class="card-body pb-1 text-center">
              <iframe
                title=""
                height="640px"
                width="100%"
                src="https://bi.goadroit.com/embed/query/9/visualization/15?api_key=zI3ZeqdqwsGc7NNmt5opylTBPtgZEfxzkRjgRQOU&p_date%20range=d_last_7_days&p_Status=All&hide_link&hide_header"
              ></iframe>
            </div>
          </div>
        </div>

        <div class="col-lg-6 draggable">
          <div class="card {{ isMoveEnable ? 'MoveEnabled' : '' }}">
            <div class="card-header">
              <div
                class="moveIcon"
                *ngIf="isMoveEnable"
              >
                <span
                  [data-feather]="'move'"
                  [class]="'mr-25'"
                ></span>
              </div>
              <h4 class="card-title text-primary">Lead Conversion</h4>
              <h6 class="card-title">AVERAGE BY WEEK</h6>
            </div>
            <div class="card-body pb-1 text-center">
              <iframe
                title=""
                seamless
                height="640px;"
                width="100%"
                src="https://bi.goadroit.com/embed/query/12/visualization/23?api_key=wjyOeRSaFWipxvOQxObsT4K6agE72JN4D0PWnJMx&p_DateRange=d_last_month&p_State=All%20States&hide_link&hide_header"
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
