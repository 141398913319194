<!-- Footer -->
<p class="clearfix mb-0 text-center">
  <span class="d-none d-md-block">
    ADROIT Advanced Technologies LLC / BI Portal V.2.0
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
