import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "environments/environment";

@Injectable()
export class DashboardService {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {}

  getFinancialStaticties(Year) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}chart-statistics?FromDate=7-1-${Year}&ToDate=6-30-${
        parseInt(Year) + 1
      }&FunName=FinancialDashboard`,
    );
  }
  getPerformanceStaticties(FromDate, ToDate) {
    return this._httpClient.get<any>(
      `${environment.apiUrl}chart-statistics?FromDate=${FromDate}&ToDate=${ToDate}&FunName=PerformaceAdminDashboard`,
    );
  }
}
