<ng-template *ngIf="dialogconfig.type == 'view'"></ng-template>
<div class="modal-size-lg d-inline-block">
  <ng-template
    #modalLG
    let-modal
  >
    <div class="modal-header">
      <h5
        class="modal-title"
        id="myModalLabel160"
      >
        {{ dialogconfig.header }}
      </h5>
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      class="modal-body"
      tabindex="0"
      ngbAutofocus
    >
      <div class="invoice-preview-card">
        <!-- Header starts -->
        <div class="card-body invoice-padding pb-0">
          <div class="logo-wrapper">
            <h5 class="text-primary invoice-logo mt-2 mb-0">
              {{ dialogconfig.subhHeader }}
            </h5>
          </div>
        </div>
        <hr class="invoice-spacing mt-0" />

        <div class="card-body invoice-padding pb-0">
          <div
            class="row"
            style="margin-bottom: -35px"
          >
            <div class="col-lg-7">
              <div class="row g-6 align-items-center">
                <div class="col-md-4">
                  <label
                    for="inputPassword6"
                    class="col-form-label"
                    >Student Name</label
                  >
                </div>
                <div class="col">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    value="{{ dialogconfig.studentName }}"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-7">
              <div class="row">
                <div class="col-md-4">
                  <label
                    for="inputPassword6"
                    class="col-form-label"
                    >Client Name</label
                  >
                </div>
                <div class="col">
                  <span>{{ dialogconfig.clientName }}</span>
                </div>
              </div>
            </div>

            <div class="col-lg-5">
              <div class="row">
                <div class="col-lg-12 pb-0">
                  <div class="info-container">
                    <div class="info-title">Date</div>
                    <div class="info-value">{{ dialogconfig.date }}</div>
                  </div>
                  <div class="info-container">
                    <div class="info-title">Number of Incident</div>
                    <div class="info-value">
                      {{ dialogconfig.numberofIncident }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="invoice-spacing mt-0" />

        <div class="card-body invoice-padding py-0">
          <!-- Invoice Note starts -->
          <div class="row">
            <div class="col-12 pb-0">
              <div class="form-group mb-2">
                <label
                  for="note"
                  class="form-label font-weight-bold"
                  >Incident Description:</label
                >
                <textarea
                  class="form-control"
                  rows="5"
                  disabled
                  >{{ dialogconfig.incidentDescription }}</textarea
                >
              </div>
            </div>
          </div>
          <!-- Invoice Note ends -->
        </div>

        <div class="card-body invoice-padding py-0">
          <!-- Invoice Note starts -->
          <div class="row">
            <div class="col-12 pb-0">
              <div class="form-group mb-2">
                <label
                  for="note"
                  class="form-label font-weight-bold"
                  >Action Taken and Recommendations:</label
                >
                <textarea
                  class="form-control"
                  rows="5"
                  disabled
                  >{{ dialogconfig.actionTakenAndRecommendations }}</textarea
                >
              </div>
            </div>
          </div>
          <!-- Invoice Note ends -->
        </div>

        <div class="card-body invoice-padding py-0">
          <!-- Invoice Note starts -->
          <div class="row">
            <div class="col-12 pb-0">
              <div class="form-group mb-2">
                <label
                  for="note"
                  class="form-label font-weight-bold"
                  >Notes:</label
                >
                <textarea
                  class="form-control"
                  id="note"
                  rows="3"
                  disabled
                  >{{ dialogconfig.Notes }}</textarea
                >
              </div>
            </div>
          </div>
          <!-- Invoice Note ends -->
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-info"
        (click)="openIncidentReportPrint()"
        rippleEffect
      >
        {{ dialogconfig.buttonTitle }}
      </button>
    </div>
  </ng-template>
</div>
