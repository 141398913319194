import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IIncidentReportDialogConfig } from "app/interfaces/controls/dialogs/IIncidentReportDialogConfig";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
})
export class ProfileComponent {
  constructor(private modalService: NgbModal) {}
  @Input() dialogconfig: IIncidentReportDialogConfig;
  @ViewChild("modalLG")
  private modalRef: TemplateRef<any>;

  modalOpen() {
    this.modalService.open(this.modalRef, {
      centered: true,
      size: "xl",
    });
  }
  modalOpenlg() {
    this.modalService.open(this.modalRef, {
      centered: true,
      size: "lg",
    });
  }
}
