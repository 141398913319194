<div
  *ngIf="horizontalMenu"
  class="navbar-header d-xl-block d-none"
>
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            height="30"
          />
        </span>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul
      class="nav navbar-nav d-xl-none"
      *ngIf="!coreConfig.layout.menu.hidden"
    >
      <li class="nav-item">
        <a
          class="nav-link menu-toggle"
          (click)="toggleSidebar('menu')"
        >
          <span
            [data-feather]="'menu'"
            [class]="'ficon'"
          ></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
    <!-- Toggle skin -->
    <ul  class="no-style-list" >
    <li class="nav-item d-none">
      <a
        type="button"
        class="nav-link nav-link-style btn"
        (click)="toggleDarkSkin()"
      >
        <span
          [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'"
          class="ficon font-medium-5 feather"
        ></span>
      </a>
    </li>
    </ul>
    <!--/ Toggle skin -->
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <app-navbar-chat></app-navbar-chat>
    <!-- Notification -->

    <app-navbar-notification></app-navbar-notification>
    <!--/ Notification -->
    <!-- User Dropdown -->
    <li
      ngbDropdown
      class="nav-item dropdown-user"
    >
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span
            class="user-name font-weight-bolder"
            id="username"
            *ngIf="currentUser != null"
            [innerHTML]="currentUser.full_name"
          ></span>
        </div>
        <a
          class="cui-avatar-nav-bar cui-avatar-border-white cui-avatar-90 bg-pink"
          href="javascript:void(0);"
        >
          <span
            class="app-profile-avatar-nav-bar"
            id="account_profile_avatar"
            style="margin-top: 17px"
            >{{ currentUser?.short_name }}</span
          >
        </a>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a
          ngbDropdownItem
          [routerLink]="['/Profile']"
          ><span
            [data-feather]="'user'"
            [class]="'mr-50'"
          ></span>
          Profile</a
        ><a
          ngbDropdownItem
          [routerLink]="['/']"
          disabled
          ><span
            [data-feather]="'mail'"
            [class]="'mr-50 disabled'"
          ></span>
          Inbox</a
        >
        <a
          ngbDropdownItem
          [routerLink]="['/']"
          disabled
          ><span
            [data-feather]="'message-square'"
            [class]="'mr-50 disabled'"
          ></span>
          Chats</a
        >
        <div class="dropdown-divider"></div>
        <a
          ngbDropdownItem
          (click)="logout()"
          ><span
            [data-feather]="'power'"
            [class]="'mr-50'"
          ></span>
          Logout</a
        >
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
