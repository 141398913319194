<ul  class="no-style-list" >
<li
  ngbDropdown
  class="nav-item dropdown-notification mr-25"
>
  <a
    class="nav-link"
    ngbDropdownToggle
    id="navbarNotificationDropdown"
    ><i
      class="ficon"
      data-feather="bell"
    ></i
    ><span class="badge badge-pill badge-primary badge-up">
      0
    </span></a
  >
</li>
</ul>