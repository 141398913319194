import { Component } from "@angular/core";

// Interface
interface Notification {
  messages: [];
  systemMessages: [];
  system: boolean;
}

@Component({
  selector: "app-navbar-chat",
  templateUrl: "./navbar-chat.component.html",
})
export class NavbarchatIconComponent {}
