import { NgModule } from "@angular/core";

import { FilterPipe } from "@core/pipes/filter.pipe";

import { InitialsPipe } from "@core/pipes/initials.pipe";

import { StripHtmlPipe } from "@core/pipes/stripHtml.pipe";

@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe],
})
export class CorePipesModule {}
