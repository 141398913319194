import { Pipe, PipeTransform } from "@angular/core";

let StringMask = require("string-mask");

@Pipe({ name: "TinMask" })
export class TinMask implements PipeTransform {
  transform(value: string) {
    let formatter = new StringMask("00-0000000");
    let result = formatter.apply(value);
    return result;
  }
}
