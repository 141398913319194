import { Component, Input } from "@angular/core";
import { IFilters } from "app/interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-users-types",
  templateUrl: "./users-types.component.html",
})
export class UsersTypesComponent {
  @Input("filterData") Config: IFilters;
}
