import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable()
export class PayoutlineitemService {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {}

  GetpayoutlineitemData(payoutLineItemId) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("payout_line_item_id", payoutLineItemId);
    return this._httpClient.get<any>(
      `${environment.apiUrl}partners-payout-report/payout-line-item-details`,
      { params: queryParams },
    );
  }
}
