import { Component, OnInit } from "@angular/core";

import { locale as en } from "./i18n/en";
import { locale as fr } from "./i18n/fr";
import { locale as de } from "./i18n/de";
import { locale as pt } from "./i18n/pt";

import { CoreTranslationService } from "@core/services/translation.service";

@Component({
  selector: "app-sample",
  templateUrl: "./sample.component.html",
})
export class SampleComponent implements OnInit {
  public contentHeader: object;

  /**
   *
   * @param {CoreTranslationService} _coreTranslationService
   */
  constructor(private _coreTranslationService: CoreTranslationService) {
    this._coreTranslationService.translate(en, fr, de, pt);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.contentHeader = {
      headerTitle: "Home",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
          },
          {
            name: "Sample",
            isLink: false,
          },
        ],
      },
    };
  }
}
