export enum ContantEnum {
  primary = "widget-primary",
  warning = "widget-warning",
  success = "widget-succes",
  light = "widget-light",
  danger = "widget-danger",
  secondary = "widget-secondary",
}

export enum TextEnum {
  primary = "text-primary",
  secondary = "text-secondary",
}

export enum SymbolsEnum {
  none = "",
  dolar = "$",
  Percantage = "%",
}
