<div class="row">
  <div class="col-lg-12 col-md-12">
    <div class="card widget-card h-100">
      <div class="card-body">
        <div class="row">
          <div
            class="col-lg col-md-4 col-sm-4 pb-lg-0"
            *ngFor="let a of statisticsList; let index = index"
          >
            <div
              [class]="a.divClass"
              class="widget-body"
            >
              <span
                [class]="a.labelClass"
                style="font-weight: 100"
                >{{ a.label }}</span
              >
              <h3 [class]="a.valueClass">
                {{ a.valueSymbol }}{{ a.value.toString() }}
                <span [class]="a.valueLabelClass">{{ a.valueLabel }}</span>
              </h3>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
