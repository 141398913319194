import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { AppComponent } from "app/app.component";
import moment from "moment";
import { DragulaService } from "ng2-dragula";
import { FlatpickrOptions } from "ng2-flatpickr";
import { ToastrService } from "ngx-toastr";
import { DashboardService } from "./service/dashboard.service";

@Component({
  selector: "app-performace",
  templateUrl: "./performace.component.html",
  styleUrls: ["./performace.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PerformaceComponent implements OnInit {
  public contentHeader: object;
  public isMoveEnable = false;
  public fromDate;
  public toDate;

  public LEADS = 0;
  public CONVERTEDDRIVERS = 0;
  public ASSIGNEDDRIVERS = 0;
  public NONCOMPLIANTDRIVERS = 0;
  public ACTIVESTUDENTS = 0;
  public ACTIVEBLUEPRINTS = 0;
  public oldfromdate;
  public oldtodate;

  constructor(
    private dragulaService: DragulaService,
    private _toastrService: ToastrService,
    private _appComponent: AppComponent,
    private _dashboardService: DashboardService,
    private cd: ChangeDetectorRef,
  ) {
    // Drag And Drop With Handle
  }

  // Lif
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  public EditBoard(): void {
    if (this.isMoveEnable) {
      this.isMoveEnable = false;
    } else {
      this.isMoveEnable = true;
    }
  }

  public FromDateOptions: FlatpickrOptions = {
    altInput: true,
  };

  public ToDateOptions: FlatpickrOptions = {
    altInput: true,
  };

  /**
   * On init
   */
  ngOnInit() {
    this.contentHeader = {
      headerTitle: "Home",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
          },
          {
            name: "Sample",
            isLink: false,
          },
        ],
      },
    };
    setTimeout(() => {
      this.fromDate = moment(
        new Date().setDate(new Date().getDate() - 30),
      ).format("YYYY/MM/DD");
      this.toDate = moment(new Date()).format("YYYY/MM/DD");
      this.changedate();
      this.cd.detectChanges();
      window.dispatchEvent(new Event("resize"));
    }, 700);
  }

  getStatisticsData() {
    this._appComponent.toggeleLoader();
    this._dashboardService
      .getPerformanceStaticties(
        moment(new Date(this.fromDate)).format("YYYY/MM/DD"),
        moment(new Date(this.toDate)).format("YYYY/MM/DD"),
      )
      .subscribe(
        response => {
          this.LEADS = response.CountOfLeads;
          this.CONVERTEDDRIVERS = response.CountOfConvertedDrivers;
          this.ASSIGNEDDRIVERS = response.CountOfAssignedDrivers;
          this.NONCOMPLIANTDRIVERS = response.CountOfNonCompliantDrivers;
          this.ACTIVESTUDENTS = response.CountOfActiveStudents;
          this.ACTIVEBLUEPRINTS = response.CountOfActiveBlueprints;

          this._appComponent.toggeleLoader();
        },
        error => {
          if (error.status == 401) {
            this._toastrService.error("UnAuthorized.", "");
            window.location.href = "/";
          } else {
            this._toastrService.error(error.error.Message, "");
          }
          this._appComponent.toggeleLoader();
        },
      );
  }
  changedate() {
    if (this.fromDate && this.toDate) {
      const dateOne = new Date(this.fromDate);
      const dateTwo = new Date(this.toDate);
      if (dateOne > dateTwo) {
        this._toastrService.warning(
          "To Date must be grater than From Date.",
          "",
        );
        this.fromDate = moment(new Date(this.oldfromdate)).format("YYYY/MM/DD");
        this.toDate = moment(new Date(this.oldtodate)).format("YYYY/MM/DD");
        this.cd.detectChanges();
      } else {
        this.oldfromdate = moment(new Date(this.fromDate)).format("YYYY/MM/DD");
        this.oldtodate = moment(new Date(this.toDate)).format("YYYY/MM/DD");
        this.getStatisticsData();
      }
    } else {
      this._toastrService.warning("Select a date.", "");
    }
  }
}
