import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { CoreCommonModule } from "@core/common.module";

import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CardSnippetModule } from "@core/components/card-snippet/card-snippet.module";

import { CsvModule } from "@ctrl/ngx-csv";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";

import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { ControlsModule } from "app/controls/controls.module";
import { PipesModule } from "app/utilities/customPipes/customPipesModule";
import { ReportHeaderComponent } from "./components/report-header/report-header.component";

@NgModule({
  declarations: [ReportHeaderComponent],
  imports: [
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    NgbModule,
    NgSelectModule,
    CardSnippetModule,
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: "No data to display", // Message to show when array is presented, but contains no values
        totalMessage: "total records", // Footer total message
        selectedMessage: "selected", // Footer selected message
      },
    }),
    Ng2FlatpickrModule,
    CsvModule,
    ControlsModule,
    PipesModule,
  ],
  exports: [ReportHeaderComponent],
})
export class SharedModule {}
