<ng-template
  #PayoutLine
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      Payout Line Item Details for Ride #
      <span id="ride_sheet_number">{{ dialogconfig.RideId }} </span>
      <span [innerHtml]="Ride(dialogconfig)"></span>
    </h5>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    ngbAutofocus
  >
    <div class="invoice-preview-card mb-2">
      <div class="card-body invoice-padding">
        <div>
          <div class="logo-wrapper">
            <h5 class="text-primary invoice-logo mt-2">Overview</h5>
          </div>
        </div>

        <hr class="m-0" />
        <!-- Invoice Note starts -->
        <div class="row mt-2">
          <div class="col-12 pb-0">
            <table class="table table-hover border mb-1 sub-table">
              <caption></caption>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Service Fee ({{ dialogconfig.TypeOfService }})</td>
                  <td>1</td>
                  <td>${{ dialogconfig.BaseFare | number : ".2-2" }}</td>
                  <td>${{ dialogconfig.BaseFare | number : ".2-2" }}</td>
                </tr>
                <tr>
                  <td>
                    Additional Miles Fee (first
                    {{
                      dialogconfig.MilesIncludedInBaseRideFee | number : ".1-2"
                    }}
                    miles included)
                  </td>
                  <td>
                    {{ dialogconfig.AdditionalMiles | number : ".1-2" }}
                  </td>
                  <td>
                    ${{ dialogconfig.AdditionalMileFee | number : ".2-2" }}
                  </td>
                  <td>
                    ${{
                      dialogconfig.CalculatedAdditionalMilesFee
                        | number : ".2-2"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Tolls</td>
                  <td>
                    {{ dialogconfig.NumberOfTolls }}
                  </td>
                  <td>${{ dialogconfig.TollFee | number : ".2-2" }}</td>
                  <td>
                    ${{ dialogconfig.CalculatedTollFee | number : ".2-2" }}
                  </td>
                </tr>
                <tr>
                  <td>Blueprint Adjustment</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    ${{
                      dialogconfig.BlueprintAdjustmentAmount | number : ".2-2"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Contractor Specific Adjustment</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    ${{
                      dialogconfig.PartnerAdjustmentAmount | number : ".2-2"
                    }}
                  </td>
                </tr>
                <tr *ngIf="dialogconfig.PartnerPayoutAfterWithhold == 0">
                  <td>Ride Not Serviced (see ride status)</td>
                  <td></td>
                  <td></td>
                  <td class="text-danger">
                    ${{ RideNotServiced(dialogconfig) }}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>
                    ${{ dialogconfig.CalculatedPayoutTotal | number : ".2-2" }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3">
                    Driver Provider Payout Withhold Percentage
                  </td>

                  <td>
                    {{ dialogconfig.OrganizationPayoutWithholdPercentage }}%
                  </td>
                </tr>
                <tr>
                  <td colspan="3">Driver Provider Payout Withhold Amount</td>

                  <td>
                    ${{
                      dialogconfig.OrganizationPayoutWithholdAmount
                        | number : ".2-2"
                    }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    Contractor Payout Percentage After Withhold
                  </td>

                  <td>
                    {{
                      dialogconfig.PartnerPayoutPercentageAfterWithhold
                        | number : ".2-2"
                    }}%
                  </td>
                </tr>
                <tr>
                  <td colspan="3">Contractor Payout After Withhold</td>

                  <td>
                    ${{
                      dialogconfig.PartnerPayoutAfterWithhold | number : ".2-2"
                    }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <!-- Invoice Note ends -->
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-info"
      (click)="modal.close('Accept click')"
      rippleEffect
    >
      Close
    </button>
  </div>
</ng-template>
