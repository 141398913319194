import { Component, Input } from "@angular/core";
import { IFilters } from "app/interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-state",
  templateUrl: "./state.component.html",
})
export class StateComponent {
  @Input("filterData") Config: IFilters;

  ngOnInit(): void {
    if (!this.Config.filterValues || this.Config.filterValues.length == 0) {
      let states = [
        { id: "CA", name: "CA" },
        { id: "AZ", name: "AZ" },
      ];
      this.Config.filterValues = states;
    }
  }
}
