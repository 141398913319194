import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "../service/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (
      this._authenticationService.checkaccess(route.data.PermissionPageName[0])
    )
      return true;
    else {
      this._router.navigate(["/pages/miscellaneous/error"]);
      return false;
    }
  }
}
