export enum PermissionsList {
  AdminHomeView = 1,
  AdminHomeCreate = 2,
  AdminHomeEdit = 3,
  AdminHomeDelete = 4,
  AdminHomeArchive = 5,
  AdroitStaffView = 6,
  AdroitStaffCreate = 7,
  AdroitStaffEdit = 8,
  AdroitStaffDelete = 9,
  AdroitStaffArchive = 10,
  RolePermissionView = 11,
  RolePermissionCreate = 12,
  RolePermissionEdit = 13,
  RolePermissionDelete = 14,
  RolePermissionArchive = 15,
  ContractorsAdditionalChecksView = 16,
  ContractorsAdditionalChecksCreate = 17,
  ContractorsAdditionalChecksEdit = 18,
  ContractorsAdditionalChecksDelete = 19,
  ContractorsAdditionalChecksArchive = 20,
  AppUserSupportView = 21,
  AppUserSupportCreate = 22,
  AppUserSupportEdit = 23,
  AppUserSupportDelete = 24,
  AppUserSupportArchive = 25,
  AnnualRevenueReportView = 26,
  AnnualRevenueReportCreate = 27,
  AnnualRevenueReportEdit = 28,
  AnnualRevenueReportDelete = 29,
  AnnualRevenueReportArchive = 30,
  ClientActivityReportView = 31,
  ClientActivityReportCreate = 32,
  ClientActivityReportEdit = 33,
  ClientActivityReportDelete = 34,
  ClientActivityReportArchive = 35,
  ClientInvoicesView = 36,
  ClientInvoicesCreate = 37,
  ClientInvoicesEdit = 38,
  ClientInvoicesDelete = 39,
  ClientInvoicesArchive = 40,
  WeeklyPayoutsView = 41,
  WeeklyPayoutsCreate = 42,
  WeeklyPayoutsEdit = 43,
  WeeklyPayoutsDelete = 44,
  WeeklyPayoutsArchive = 45,
  StatementOfAccountsView = 46,
  StatementOfAccountsCreate = 47,
  StatementOfAccountsEdit = 48,
  StatementOfAccountsDelete = 49,
  StatementOfAccountsArchive = 50,
  PayoutAdjustmentsReportView = 51,
  PayoutAdjustmentsReportCreate = 52,
  PayoutAdjustmentsReportEdit = 53,
  PayoutAdjustmentsReportDelete = 54,
  PayoutAdjustmentsReportArchive = 55,
  Annual1099ReportView = 56,
  Annual1099ReportCreate = 57,
  Annual1099ReportEdit = 58,
  Annual1099ReportDelete = 59,
  Annual1099ReportArchive = 60,
  OAIReportView = 61,
  OAIReportCreate = 62,
  OAIReportEdit = 63,
  OAIReportDelete = 64,
  OAIReportArchive = 65,
  CPUCReportsView = 66,
  CPUCReportsCreate = 67,
  CPUCReportsEdit = 68,
  CPUCReportsDelete = 69,
  CPUCReportsArchive = 70,
  ContractorsPayoutPricelistView = 71,
  ContractorsPayoutPricelistCreate = 72,
  ContractorsPayoutPricelistEdit = 73,
  ContractorsPayoutPricelistDelete = 74,
  ContractorsPayoutPricelistArchive = 75,
  ServiceCoverageView = 76,
  ServiceCoverageCreate = 77,
  ServiceCoverageEdit = 78,
  ServiceCoverageDelete = 79,
  ServiceCoverageArchive = 80,
  BonusesView = 81,
  BonusesCreate = 82,
  BonusesEdit = 83,
  BonusesDelete = 84,
  BonusesArchive = 85,
  MonitoringCenterView = 86,
  MonitoringCenterCreate = 87,
  MonitoringCenterEdit = 88,
  MonitoringCenterDelete = 89,
  MonitoringCenterArchive = 90,
  ContractorsMessagesView = 91,
  ContractorsMessagesCreate = 92,
  ContractorsMessagesEdit = 93,
  ContractorsMessagesDelete = 94,
  ContractorsMessagesArchive = 95,
  GuardiansMessagesView = 96,
  GuardiansMessagesCreate = 97,
  GuardiansMessagesEdit = 98,
  GuardiansMessagesDelete = 99,
  GuardiansMessagesArchive = 100,
  RideSimulatorView = 101,
  RideSimulatorCreate = 102,
  RideSimulatorEdit = 103,
  RideSimulatorDelete = 104,
  RideSimulatorArchive = 105,
  RideFinderView = 106,
  RideFinderCreate = 107,
  RideFinderEdit = 108,
  RideFinderDelete = 109,
  RideFinderArchive = 110,
  IncidentReportsView = 111,
  IncidentReportsCreate = 112,
  IncidentReportsEdit = 113,
  IncidentReportsDelete = 114,
  IncidentReportsArchive = 115,
  NoShowReportView = 116,
  NoShowReportCreate = 117,
  NoShowReportEdit = 118,
  NoShowReportDelete = 119,
  NoShowReportArchive = 120,
  ArrivalTimeReportView = 121,
  ArrivalTimeReportCreate = 122,
  ArrivalTimeReportEdit = 123,
  ArrivalTimeReportDelete = 124,
  ArrivalTimeReportArchive = 125,
  DeletedRidesReportView = 126,
  DeletedRidesReportCreate = 127,
  DeletedRidesReportEdit = 128,
  DeletedRidesReportDelete = 129,
  DeletedRidesReportArchive = 130,
  ClientAssistantsView = 131,
  ClientAssistantsCreate = 132,
  ClientAssistantsEdit = 133,
  ClientAssistantsDelete = 134,
  ClientAssistantsArchive = 135,
  ClientsView = 136,
  ClientsCreate = 137,
  ClientsEdit = 138,
  ClientsDelete = 139,
  ClientsArchive = 140,
  PlacesView = 141,
  PlacesCreate = 142,
  PlacesEdit = 143,
  PlacesDelete = 144,
  PlacesArchive = 145,
  RideBlueprintsView = 146,
  RideBlueprintsCreate = 147,
  RideBlueprintsEdit = 148,
  RideBlueprintsDelete = 149,
  RideBlueprintsArchive = 150,
  RideFulfillmentView = 151,
  RideFulfillmentCreate = 152,
  RideFulfillmentEdit = 153,
  RideFulfillmentDelete = 154,
  RideFulfillmentArchive = 155,
  GuardiansAppUsersView = 156,
  GuardiansAppUsersCreate = 157,
  GuardiansAppUsersEdit = 158,
  GuardiansAppUsersDelete = 159,
  GuardiansAppUsersArchive = 160,
  StudentsView = 161,
  StudentsCreate = 162,
  StudentsEdit = 163,
  StudentsDelete = 164,
  StudentsArchive = 165,
  StudentsRideRequestsView = 166,
  StudentsRideRequestsCreate = 167,
  StudentsRideRequestsEdit = 168,
  StudentsRideRequestsDelete = 169,
  StudentsRideRequestsArchive = 170,
  ContractorLeadsView = 171,
  ContractorLeadsCreate = 172,
  ContractorLeadsEdit = 173,
  ContractorLeadsDelete = 174,
  ContractorLeadsArchive = 175,
  ContractorsView = 176,
  ContractorsCreate = 177,
  ContractorsEdit = 178,
  ContractorsDelete = 179,
  ContractorsArchive = 180,
  ContractorsComplianceView = 181,
  ContractorsComplianceCreate = 182,
  ContractorsComplianceEdit = 183,
  ContractorsComplianceDelete = 184,
  ContractorsComplianceArchive = 185,
  ServiceProvidersView = 186,
  ServiceProvidersCreate = 187,
  ServiceProvidersEdit = 188,
  ServiceProvidersDelete = 189,
  ServiceProvidersArchive = 190,
  PayoutStatementsView = 191,
  PayoutStatementsCreate = 192,
  PayoutStatementsEdit = 193,
  PayoutStatementsDelete = 194,
  PayoutStatementsArchive = 195,
  DriversReportView = 196,
  DriversReportCreate = 197,
  DriversReportEdit = 198,
  DriversReportDelete = 199,
  DriversReportArchive = 200,
  RideQualityReportView = 201,
  RideQualityReportCreate = 202,
  RideQualityReportEdit = 203,
  RideQualityReportDelete = 204,
  RideQualityReportArchive = 205,
  ClientHomeView = 206,
  ClientHomeCreate = 207,
  ClientHomeEdit = 208,
  ClientHomeDelete = 209,
  ClientHomeArchive = 210,
  ClientClientInvoicesView = 211,
  ClientClientInvoicesCreate = 212,
  ClientClientInvoicesEdit = 213,
  ClientClientInvoicesDelete = 214,
  ClientClientInvoicesArchive = 215,
  RideBlueprintView = 216,
  RideBlueprintCreate = 217,
  RideBlueprintEdit = 218,
  RideBlueprintDelete = 219,
  RideBlueprintArchive = 220,
  RideMonitorView = 221,
  RideMonitorCreate = 222,
  RideMonitorEdit = 223,
  RideMonitorDelete = 224,
  RideMonitorArchive = 225,
  ClientStudentsView = 226,
  ClientStudentsCreate = 227,
  ClientStudentsEdit = 228,
  ClientStudentsDelete = 229,
  ClientStudentsArchive = 230,
  ClientNoShowReportView = 231,
  ClientNoShowReportCreate = 232,
  ClientNoShowReportEdit = 233,
  ClientNoShowReportDelete = 234,
  ClientNoShowReportArchive = 235,
  ServiceProviderHomeView = 236,
  ServiceProviderHomeCreate = 237,
  ServiceProviderHomeEdit = 238,
  ServiceProviderHomeDelete = 239,
  ServiceProviderHomeArchive = 240,
  DriversandAidesView = 241,
  DriversandAidesCreate = 242,
  DriversandAidesEdit = 243,
  DriversandAidesDelete = 244,
  DriversandAidesArchive = 245,
  RideActivityView = 246,
  RideActivityCreate = 247,
  RideActivityEdit = 248,
  RideActivityDelete = 249,
  RideActivityArchive = 250,
  ServiceProviderRideMonitorView = 251,
  ServiceProviderRideMonitorCreate = 252,
  ServiceProviderRideMonitorEdit = 253,
  ServiceProviderRideMonitorDelete = 254,
  ServiceProviderRideMonitorArchive = 255,
  ServiceProviderRideBluePrintView = 256,
  ServiceProviderRideBluePrintCreate = 257,
  ServiceProviderRideBluePrintEdit = 258,
  ServiceProviderRideBluePrintDelete = 259,
  ServiceProviderRideBluePrintArchive = 260,
  ServiceProviderPayoutsView = 261,
  ServiceProviderPayoutsCreate = 262,
  ServiceProviderPayoutsEdit = 263,
  ServiceProviderPayoutsDelete = 264,
  ServiceProviderPayoutsArchive = 265,
  DriversWeeklyPayoutsView = 266,
  DriversWeeklyPayoutsCreate = 267,
  DriversWeeklyPayoutsEdit = 268,
  DriversWeeklyPayoutsDelete = 269,
  DriversWeeklyPayoutsArchive = 270,
  FinancialDashboardView = 271,
  FinancialDashboardCreate = 272,
  FinancialDashboardEdit = 273,
  FinancialDashboardDelete = 274,
  FinancialDashboardArchive = 275,
  PerformaceDashboardView = 276,
  PerformaceDashboardCreate = 277,
  PerformaceDashboardEdit = 278,
  PerformaceDashboardDelete = 279,
  PerformaceDashboardArchive = 280,
  FinancialOverviewAnnualRevenueView = 281,
  FinancialOverviewAnnualRevenueCreate = 282,
  FinancialOverviewAnnualRevenueEdit = 283,
  FinancialOverviewAnnualRevenueDelete = 284,
  FinancialOverviewAnnualRevenueArchive = 285,
  FinancialOverviewClientInvoicesView = 286,
  FinancialOverviewClientInvoicesCreate = 287,
  FinancialOverviewClientInvoicesEdit = 288,
  FinancialOverviewClientInvoicesDelete = 289,
  FinancialOverviewClientInvoicesArchive = 290,
  FinancialOverviewPayoutActivityReportView = 291,
  FinancialOverviewPayoutActivityReportCreate = 292,
  FinancialOverviewPayoutActivityReportEdit = 293,
  FinancialOverviewPayoutActivityReportDelete = 294,
  FinancialOverviewPayoutActivityReportArchive = 295,
  FinancialOverviewContractorsAnnualReportView = 296,
  FinancialOverviewContractorsAnnualReportCreate = 297,
  FinancialOverviewContractorsAnnualReportEdit = 298,
  FinancialOverviewContractorsAnnualReportDelete = 299,
  FinancialOverviewContractorsAnnualReportArchive = 300,
  FinancialOverviewOAIReportView = 301,
  FinancialOverviewOAIReportCreate = 302,
  FinancialOverviewOAIReportEdit = 303,
  FinancialOverviewOAIReportDelete = 304,
  FinancialOverviewOAIReportArchive = 305,
  PerformanceArrivalTimeReportView = 306,
  PerformanceArrivalTimeReportCreate = 307,
  PerformanceArrivalTimeReportEdit = 308,
  PerformanceArrivalTimeReportDelete = 309,
  PerformanceArrivalTimeReportArchive = 310,
  PerformanceIncidentReportView = 311,
  PerformanceIncidentReportCreate = 312,
  PerformanceIncidentReportEdit = 313,
  PerformanceIncidentReportDelete = 314,
  PerformanceIncidentReportArchive = 315,
  QualityOverviewRideQualityReportView = 316,
  QualityOverviewRideQualityReportCreate = 317,
  QualityOverviewRideQualityReportEdit = 318,
  QualityOverviewRideQualityReportDelete = 319,
  QualityOverviewRideQualityReportArchive = 320,
  PayoutsReportView = 321,
  PayoutsReportCreate = 322,
  PayoutsReportEdit = 323,
  PayoutsReportDelete = 324,
  PayoutsReportArchive = 325,
  BlueprintsReportView = 326,
  BlueprintsReportCreate = 327,
  BlueprintsReportEdit = 328,
  BlueprintsReportDelete = 329,
  BlueprintsReportArchive = 330,
  ClientsReportView = 331,
  ClientsReportCreate = 332,
  ClientsReportEdit = 333,
  ClientsReportDelete = 334,
  ClientsReportArchive = 335,
  HolidaysReportView = 336,
  HolidaysReportCreate = 337,
  HolidaysReportEdit = 338,
  HolidaysReportDelete = 339,
  HolidaysReportArchive = 340,
  PayoutStatementsUnderAccountView = 341,
  PayoutStatementsUnderAccountCreate = 342,
  PayoutStatementsUnderAccountEdit = 343,
  PayoutStatementsUnderAccountDelete = 344,
  PayoutStatementsUnderAccountArchive = 345,
}
