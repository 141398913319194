import { NgModule } from "@angular/core";
import { CoreCommonModule } from "@core/common.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { FiltersModule } from "./component/filters/filters.module";
import { StatisticsModule } from "./component/statistics/statistics.module";
import { DialogsModule } from "./component/dialogs/dialogs.module";

@NgModule({
  declarations: [],
  imports: [CoreCommonModule, NgbModule, NgSelectModule, Ng2FlatpickrModule],
  exports: [FiltersModule, StatisticsModule, DialogsModule],
})
export class ControlsModule {}
