import { Component, Input } from "@angular/core";
import { IFilters } from "app/interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-statues",
  templateUrl: "./statues.component.html",
})
export class StatuesComponent {
  @Input("filterData") Config: IFilters;
}
