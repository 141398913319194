<div
  *ngIf="Config.filterVisability"
  class="form-group"
>
  <!-- Basic Select with Search -->
  <ng2-flatpickr
    #FromDate
    [config]="FromDateOptions"
    [setDate]="Config.filterValue"
    [(ngModel)]="Config.filterValue"
    [placeholder]="Config.filterPlaceholder"
    readonly="true"
    (change)="onchange()"
  ></ng2-flatpickr>
  <!--/ Basic Select with Search -->
</div>
