import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CoreCommonModule } from "@core/common.module";
import { Ng2FlatpickrModule } from "ng2-flatpickr";

import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";

import { PerformaceComponent } from "./performace.component";
import { FinancialComponent } from "./financial.component";
import { DragulaModule } from "ng2-dragula";
import { AuthGuard } from "app/auth/helpers";
import { AuthorizationGuard } from "app/auth/helpers/authorization.guard";
import { DashboardService } from "./service/dashboard.service";
import { ControlsModule } from "app/controls/controls.module";
import { PermissionsList } from "app/enums/Permissions/PermissionList";

const routes = [
  {
    path: "performace",
    component: PerformaceComponent,
    data: {
      animation: "performace",
      PermissionPageName: [
        PermissionsList[PermissionsList.PerformaceDashboardView],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
  {
    path: "financial",
    component: FinancialComponent,
    data: {
      animation: "financial",
      PermissionPageName: [
        PermissionsList[PermissionsList.FinancialDashboardView],
      ],
    },
    canActivate: [AuthGuard, AuthorizationGuard],
  },
];

@NgModule({
  declarations: [PerformaceComponent, FinancialComponent],
  exports: [PerformaceComponent, FinancialComponent],
  providers: [DashboardService],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    NgbModule,
    DragulaModule.forRoot(),
    Ng2FlatpickrModule,
    ControlsModule,
  ],
})
export class DashboardModule {}
