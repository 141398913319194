import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IFilters } from "app/interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-year",
  templateUrl: "./year.component.html",
})
export class YearComponent {
  @Input("filterData") Config: IFilters;
  @Output() changeyear = new EventEmitter<string>();

  Onchange() {
    this.changeyear.emit("complete");
  }
}
