import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AppComponent } from "app/app.component";
import {
    ContantEnum,
    SymbolsEnum,
    TextEnum,
} from "app/enums/controls/HTMLConfig";
import { DragulaService } from "ng2-dragula";
import { ToastrService } from "ngx-toastr";
import { DashboardService } from "./service/dashboard.service";
import { FiscalYearModel } from "app/models/FiscalYearModel";

@Component({
    selector: "app-financial",
    templateUrl: "./financial.component.html",
    styleUrls: ["./financial.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FinancialComponent implements OnInit {
    public contentHeader: object;

    public isMoveEnable = false;
    year: number = 2021;
    class2020 = "btn-light";
    class2021 = "btn-info";
    class2022 = "btn-light";
    public listOfYears: Array<FiscalYearModel>;
    public REVENUE = "0";
    public PROFIT = "0";
    public INVOICES = "0";
    public GROSSMARGINBPAC = "0";
    public GROSSMARGINAPAC = "0";
    public CONTRACTORSCOMP = "0";

    public statistics: any[] = [
        {
            divClass: ContantEnum.primary,
            label: "REVENUE",
            labelClass: TextEnum.secondary,
            value: 0,
            valueSymbol: SymbolsEnum.dolar,
            valueClass: TextEnum.secondary,
            valueLabel: "",
            ValueLabelClass: TextEnum.primary,
        },
        {
            divClass: ContantEnum.warning,
            label: "PROFIT",
            labelClass: TextEnum.secondary,
            value: 0,
            valueSymbol: SymbolsEnum.dolar,
            valueClass: TextEnum.secondary,
            valueLabel: "",
            ValueLabelClass: TextEnum.primary,
        },
        {
            divClass: ContantEnum.success,
            label: "INVOICES",
            labelClass: TextEnum.secondary,
            value: 0,
            valueSymbol: SymbolsEnum.dolar,
            valueClass: TextEnum.secondary,
            valueLabel: "",
            ValueLabelClass: TextEnum.primary,
        },
        {
            divClass: ContantEnum.light,
            label: "GROSS MARGIN BPAC",
            labelClass: TextEnum.secondary,
            value: 0,
            valueSymbol: SymbolsEnum.dolar,
            valueClass: TextEnum.secondary,
            valueLabel: "",
            ValueLabelClass: TextEnum.primary,
        },
        {
            divClass: ContantEnum.danger,
            label: "GROSS MARGIN APAC",
            labelClass: TextEnum.secondary,
            value: 0,
            valueSymbol: SymbolsEnum.dolar,
            valueClass: TextEnum.secondary,
            valueLabel: "",
            ValueLabelClass: TextEnum.primary,
        },
        {
            divClass: ContantEnum.secondary,
            label: "CONTRACTORS COMP",
            labelClass: TextEnum.secondary,
            value: 0,
            valueSymbol: SymbolsEnum.dolar,
            valueClass: TextEnum.secondary,
            valueLabel: "",
            ValueLabelClass: TextEnum.primary,
        },
    ];

    constructor(
        private dragulaService: DragulaService,
        private _dashboardService: DashboardService,
        private _toastrService: ToastrService,
        private _appComponent: AppComponent,
        private titleService: Title,
    ) {
        titleService.setTitle("Financial Overview | ADROIT");
    }

    public EditBoard(): void {
        if (this.isMoveEnable) {
            this.isMoveEnable = false;
        } else {
            this.isMoveEnable = true;
        }
    }

    ngOnInit() {
        this.contentHeader = {
            headerTitle: "Home",
            actionButton: true,
            breadcrumb: {
                type: "",
                links: [
                    {
                        name: "Home",
                    },
                    {
                        name: "Sample",
                        isLink: false,
                    },
                ],
            },
        };
        this.prepareFiscalYears();
        setTimeout(() => {
            this.getStatictesData();
            window.dispatchEvent(new Event("resize"));
        }, 700);
    }

    getStatictesData() {
        this._appComponent.toggeleLoader();
        this._dashboardService.getFinancialStaticties(this.year).subscribe(
            response => {
                this.statistics = [
                    {
                        divClass: ContantEnum.primary,
                        label: "REVENUE",
                        labelClass: TextEnum.secondary,
                        value: Number(response.TotalRevenue).toLocaleString("en-GB"),
                        valueSymbol: SymbolsEnum.dolar,
                        valueClass: TextEnum.secondary,
                        valueLabel: "",
                        ValueLabelClass: TextEnum.primary,
                    },
                    {
                        divClass: ContantEnum.warning,
                        label: "PROFIT",
                        labelClass: TextEnum.secondary,
                        value: Number(
                            parseFloat(
                                (
                                    response.TotalRevenue - response.ContractorsCompensation
                                ).toFixed(2),
                            ),
                        ).toLocaleString("en-GB"),
                        valueSymbol: SymbolsEnum.dolar,
                        valueClass: TextEnum.secondary,
                        valueLabel: "",
                        ValueLabelClass: TextEnum.primary,
                    },
                    {
                        divClass: ContantEnum.success,
                        label: "INVOICES",
                        labelClass: TextEnum.secondary,
                        value: Number(response.TotalRevenue).toLocaleString("en-GB"),
                        valueSymbol: SymbolsEnum.dolar,
                        valueClass: TextEnum.secondary,
                        valueLabel: "",
                        ValueLabelClass: TextEnum.primary,
                    },
                    {
                        divClass: ContantEnum.light,
                        label: "GROSS MARGIN BPAC",
                        labelClass: TextEnum.secondary,
                        value: Number(response.GrossMarginBPAC).toLocaleString("en-GB"),
                        valueSymbol: SymbolsEnum.dolar,
                        valueClass: TextEnum.secondary,
                        valueLabel: "",
                        ValueLabelClass: TextEnum.primary,
                    },
                    {
                        divClass: ContantEnum.danger,
                        label: "GROSS MARGIN APAC",
                        labelClass: TextEnum.secondary,
                        value: Number(response.GrossMarginAPAC).toLocaleString("en-GB"),
                        valueSymbol: SymbolsEnum.dolar,
                        valueClass: TextEnum.secondary,
                        valueLabel: "",
                        ValueLabelClass: TextEnum.primary,
                    },
                    {
                        divClass: ContantEnum.secondary,
                        label: "CONTRACTORS COMP",
                        labelClass: TextEnum.secondary,
                        value: Number(response.ContractorsCompensation).toLocaleString(
                            "en-GB",
                        ),
                        valueSymbol: SymbolsEnum.dolar,
                        valueClass: TextEnum.secondary,
                        valueLabel: "",
                        ValueLabelClass: TextEnum.primary,
                    },
                ];

                this.REVENUE = Number(response.TotalRevenue).toLocaleString("en-GB");
                this.PROFIT = Number(
                    parseFloat(
                        (response.TotalRevenue - response.ContractorsCompensation).toFixed(
                            2,
                        ),
                    ),
                ).toLocaleString("en-GB");
                this.INVOICES = Number(response.TotalRevenue).toLocaleString("en-GB");
                this.GROSSMARGINBPAC = Number(response.GrossMarginBPAC).toLocaleString(
                    "en-GB",
                );
                this.GROSSMARGINAPAC = Number(response.GrossMarginAPAC).toLocaleString(
                    "en-GB",
                );
                this.CONTRACTORSCOMP = Number(
                    response.ContractorsCompensation,
                ).toLocaleString("en-GB");

                this._appComponent.toggeleLoader();
            },
            error => {
                if (error.status == 401) {
                    this._toastrService.error("UnAuthorized.", "");
                    window.location.href = "/";
                } else {
                    this._toastrService.error(error.error.Message, "");
                }
                this._appComponent.toggeleLoader();
            },
        );
    }

    changeYear(year: number) {
        this.listOfYears.forEach(element => {
            element.Class = element.CurrentYear == year ? "btn-info" : "btn-light";
        });

        this.year = year;
        this.getStatictesData();
    }
    prepareFiscalYears() {
        let CurrentMonth = new Date().getMonth();

        let CurrentYear = CurrentMonth >= 7 ? new Date().getFullYear() + 1 : new Date().getFullYear();
        let that = this;
        that.listOfYears = new Array<FiscalYearModel>();
        for (let i = 0; i < 3; i++) {
            let year1 = (CurrentYear - i - 1) % 100;
            let year2 = (CurrentYear - i) % 100;
            that.listOfYears.push({
                FirstYear: year1,
                SecondYear: year2,
                CurrentYear: CurrentYear - i - 1,
                Class: i == 0 ? "btn-info" : "btn-light",
            });
        }
        that.listOfYears = that.listOfYears.reverse();
    }
}
