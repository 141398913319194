import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IFilters } from "app/interfaces/controls/IFiltersConfig";

@Component({
  selector: "app-fiscal-period",
  templateUrl: "./fiscal-period.component.html",
})
export class FiscalPeriodComponent {
  @Input("filterData") Config: IFilters;
  @Output() changeValue = new EventEmitter<string>();

  Onchange() {
    this.changeValue.emit("complete");
  }
}
