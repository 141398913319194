<div
  *ngIf="Config.filterVisability"
  class="form-group"
>
  <ng2-flatpickr
    #ToDate
    [config]="ToDateOptions"
    id="ToDate"
    name="ToDate"
    [setDate]="Config.filterValue"
    [(ngModel)]="Config.filterValue"
    [placeholder]="Config.filterPlaceholder"
    readonly="true"
    (change)="onchange()"
  ></ng2-flatpickr>
</div>
