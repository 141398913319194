<!-- Main menu -->
<div class="navbar-container main-menu-content">
  <ul
    class="nav navbar-nav"
    id="main-menu-navigation"
    layout="horizontal"
    core-menu
  ></ul>
</div>
<!--/ Main menu -->
