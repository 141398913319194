import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { CoreMenu } from "@core/types";
import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { PermissionsList } from "app/enums/Permissions/PermissionList";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser")),
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Client
    );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http.post<any>(`${environment.apiUrl}auth/sign-in`, {
      email,
      password,
      api_client_name: "app-web-client",
    });
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    // notify
    this.currentUserSubject.next(null);
  }

  /**
   * check if user LoggedIn
   *
   */
  LoggedIn() {
    return !!localStorage.getItem("currentUser");
  }

  /**
   * check if user have access to a spacific page
   *
   */
  checkaccess(pagename: string): boolean {
    let userinfo = JSON.parse(localStorage.getItem("currentUser")) as User;
    if (userinfo?.Permissions?.includes(PermissionsList[pagename])) return true;
    else return false;
  }

  checkparantaccess(pagesname: string[]): boolean {
    let userinfo = JSON.parse(localStorage.getItem("currentUser")) as User;
    if (userinfo != null) {
      for (const permission of userinfo.Permissions) {
        for (const page of pagesname) {
          if (PermissionsList[parseInt(permission.toString())] === page) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getdefaultpage(array: CoreMenu[]): string {
    let userinfo = JSON.parse(localStorage.getItem("currentUser")) as User;
    if (userinfo != null) {
      for (const item of array) {
        for (const child of item.children) {
          if (
            userinfo.Permissions.includes(PermissionsList[child.permission])
          ) {
            return "/" + child.url;
          }
        }
      }
    }

    return "/";
  }

  saveUserData(userinfo: User) {
    localStorage.setItem("currentUser", JSON.stringify(userinfo));
  }

  changePassWord(OldPassword, NewPassword) {
    let body = {
      OldPassword: OldPassword,
      NewPassword: NewPassword,
    };
    return this._http.patch<any>(
      `${environment.apiUrl}accounts/me/change-password`,
      body,
    );
  }

  updateUserInfo(userinfo: User) {
    const body = {
      Id: userinfo.id,
      FirstName: userinfo.first_name,
      LastName: userinfo.last_name,
      Email: userinfo.email,
    };
    return this._http.put<any>(
      `${environment.apiUrl}accounts/${userinfo.id}`,
      body,
    );
  }
}
