<ng-template
  #modalLG
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      {{ dialogconfig.header }}
    </h5>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    ngbAutofocus
  >
    <div class="invoice-preview-card mb-2">
      <!-- Header starts -->
      <div
        class="card-body invoice-padding pb-0"
        *ngIf="dialogconfig.basicinfoVisability"
      >
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-2"
        >
          <div>
            <div class="logo-wrapper">
              <h5 class="text-primary invoice-logo m-0">Basic Information</h5>
            </div>
          </div>
        </div>
      </div>
      <hr
        class="invoice-spacing mt-0"
        *ngIf="dialogconfig.basicinfoVisability"
      />
      <div
        class="card-body invoice-padding"
        *ngIf="dialogconfig.basicinfoVisability"
      >
        <!-- Invoice Note starts -->
        <div class="row">
          <div class="col-md-6 pb-0">
            <div
              class="info-container"
              *ngIf="dialogconfig.FullAddress != null"
            >
              <div class="info-title">Full Address</div>
              <div class="info-value">{{ dialogconfig.FullAddress }}</div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.MobileNumber != null"
            >
              <div class="info-title">Mobile Number</div>
              <div class="info-value">
                {{ dialogconfig.MobileNumber | PhoneMask }}
              </div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.Email != null"
            >
              <div class="info-title">Email</div>
              <div class="info-value">{{ dialogconfig.Email }}</div>
            </div>
          </div>

          <div class="col-md-6 pb-0">
            <div
              class="info-container"
              *ngIf="dialogconfig.Gender != null"
            >
              <div class="info-title">Gender</div>
              <div class="info-value">{{ dialogconfig.Gender }}</div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.DateofBirth != null"
            >
              <div class="info-title">Date of Birth</div>
              <div class="info-value">
                {{ dialogconfig.DateofBirth | date : "MM/dd/yyyy" }}
              </div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.JoinedSince != null"
            >
              <div class="info-title">Joined Since</div>
              <div class="info-value">
                {{ dialogconfig.JoinedSince | date : "MM/dd/yyyy" }}
              </div>
            </div>
          </div>
        </div>
        <!-- Invoice Note ends -->
      </div>

      <div
        class="card-body invoice-padding pb-0"
        *ngIf="dialogconfig.VehicleDetailsVisability"
      >
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-2"
        >
          <div>
            <div class="logo-wrapper">
              <h5 class="text-primary invoice-logo m-0">Vehicle Details</h5>
            </div>
          </div>
        </div>
      </div>
      <hr
        class="invoice-spacing mt-0"
        *ngIf="dialogconfig.VehicleDetailsVisability"
      />
      <div
        class="card-body invoice-padding py-0"
        *ngIf="dialogconfig.VehicleDetailsVisability"
      >
        <!-- Invoice Note starts -->
        <div class="row">
          <div class="col-md-6 pb-0">
            <div
              class="info-container"
              *ngIf="dialogconfig.Year != null"
            >
              <div class="info-title">Year</div>
              <div class="info-value">{{ dialogconfig.Year }}</div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.Color != null"
            >
              <div class="info-title">Color</div>
              <div class="info-value">{{ dialogconfig.Color }}</div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.Make != null"
            >
              <div class="info-title">Make</div>
              <div class="info-value">{{ dialogconfig.Make }}</div>
            </div>
          </div>
          <div class="col-md-6 pb-0">
            <div
              class="info-container"
              *ngIf="dialogconfig.Model != null"
            >
              <div class="info-title">Model</div>
              <div class="info-value">{{ dialogconfig.Model }}</div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.Plate != null"
            >
              <div class="info-title">Plate #</div>
              <div class="info-value">{{ dialogconfig.Plate }}</div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.Classification != null"
            >
              <div class="info-title">Classification</div>
              <div class="info-value">{{ dialogconfig.Classification }}</div>
            </div>
          </div>
        </div>
        <!-- Invoice Note ends -->
      </div>

      <div
        class="card-body invoice-padding pb-0"
        *ngIf="dialogconfig.serviceProviderVisability"
      >
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-2"
        >
          <div>
            <div class="logo-wrapper">
              <h5 class="text-primary invoice-logo m-0">Basic Information</h5>
            </div>
          </div>
        </div>
      </div>
      <hr
        class="invoice-spacing mt-0"
        *ngIf="dialogconfig.serviceProviderVisability"
      />
      <div
        class="card-body invoice-padding py-0"
        *ngIf="dialogconfig.serviceProviderVisability"
      >
        <!-- Invoice Note starts -->
        <div class="row">
          <div class="col-md-12 pb-0">
            <div
              class="info-container"
              *ngIf="dialogconfig.State != null"
            >
              <div class="info-title">State</div>
              <div class="info-value">{{ dialogconfig.State }}</div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.TaxIdentificationNumbers != null"
            >
              <div class="info-title">Tax Identification Numbers (TIN)</div>
              <div class="info-value">
                {{ dialogconfig.TaxIdentificationNumbers | TinMask }}
              </div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.DriverDefaultWithholdPercentage != null"
            >
              <div class="info-title">Driver Default Withhold Percentage</div>
              <div class="info-value">
                {{ dialogconfig.DriverDefaultWithholdPercentage }}%
              </div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.AideDefaultWithholdPercentage != null"
            >
              <div class="info-title">Monitor Default Withhold Percentage</div>
              <div class="info-value">
                {{ dialogconfig.AideDefaultWithholdPercentage }}%
              </div>
            </div>
          </div>
        </div>
        <!-- Invoice Note ends -->
      </div>

      <div
        class="card-body invoice-padding pb-0"
        *ngIf="dialogconfig.BankVisability"
      >
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-2"
        >
          <div>
            <div class="logo-wrapper">
              <h5 class="text-primary invoice-logo m-0">Bank Information</h5>
            </div>
          </div>
        </div>
      </div>
      <hr
        class="invoice-spacing mt-0"
        *ngIf="dialogconfig.BankVisability"
      />
      <div
        class="card-body invoice-padding py-0"
        *ngIf="dialogconfig.BankVisability"
      >
        <!-- Invoice Note starts -->
        <div class="row">
          <div class="col-md-6 pb-0">
            <div
              class="info-container"
              *ngIf="dialogconfig.BankName != null"
            >
              <div class="info-title">Bank Name</div>
              <div class="info-value">{{ dialogconfig.BankName }}</div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.HolderName != null"
            >
              <div class="info-title">Holder Name</div>
              <div class="info-value">{{ dialogconfig.HolderName }}</div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.AccountNumber != null"
            >
              <div class="info-title">Account Number</div>
              <div class="info-value">
                {{ dialogconfig.AccountNumber | BankNumbersMask }}
              </div>
            </div>
          </div>

          <div class="col-md-6 pb-0">
            <div
              class="info-container"
              *ngIf="dialogconfig.RoutingNumber != null"
            >
              <div class="info-title">Routing Number</div>
              <div class="info-value">
                {{ dialogconfig.RoutingNumber | BankNumbersMask }}
              </div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.AccountType != null"
            >
              <div class="info-title">Status</div>
              <div class="info-value">
                {{ dialogconfig.AccountType | Titlevalue }}
              </div>
            </div>
            <div
              class="info-container"
              *ngIf="dialogconfig.Status != null"
            >
              <div class="info-title">Status</div>
              <span
                class="font-size-12 badge badge-success"
                *ngIf="dialogconfig.Status == true"
                >Verified</span
              >
              <span
                class="font-size-12 badge badge-danger"
                *ngIf="dialogconfig.Status == false"
                >Not Verified</span
              >
            </div>
          </div>
        </div>
        <!-- Invoice Note ends -->
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-info"
      (click)="modal.close('Accept click')"
      rippleEffect
    >
      close
    </button>
  </div>
</ng-template>
