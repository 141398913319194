<div
  *ngIf="Config.filterVisability"
  class="form-group"
>
  <!-- Basic Select with Search -->
  <ng-select
    [(ngModel)]="Config.filterValue"
    [clearable]="Config.filterClear"
    [disabled]="Config.filterDisabled"
    [multiple]="Config.filterMultiple"
    [placeholder]="Config.filterPlaceholder"
  >
    <ng-option
      *ngFor="let option of Config.filterValues"
      [value]="option.id"
      >{{ option.name }}</ng-option
    >
  </ng-select>
  <!--/ Basic Select with Search -->
</div>
