<ng-template
  #RideSummary
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      {{ dialogconfig.header }}
      <span [innerHtml]="Ride(dialogconfig)"></span>
    </h5>

    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    ngbAutofocus
  >
    <div class="card-body invoice-padding py-0">
      <div class="my-2">
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <div>
            <div class="logo-wrapper">
              <h5 class="text-primary invoice-logo">Basic Details</h5>
            </div>
          </div>
        </div>
        <hr class="m-0 mb-2" />
      </div>
      <!-- Invoice Note starts -->
      <div class="row">
        <div class="col-lg-6 pb-0">
          <div class="info-container">
            <div class="info-title">Period</div>
            <div class="info-value">{{ dialogconfig.RidePeriod }}</div>
          </div>
          <div class="info-container">
            <div class="info-title">Duration</div>
            <div class="info-value">
              {{
                dialogconfig.EstimatedDurationInMinutes
                  | formatDurationInMinutes
              }}
              ({{ dialogconfig.EstimatedDistanceInMiles }} mi)
            </div>
          </div>
          <div class="info-container">
            <div class="info-title">Client(s)</div>
            <div
              class="info-value"
              [innerHtml]="dialogconfig.Clients"
            ></div>
          </div>
          <div class="info-container">
            <div class="info-title">Type</div>
            <div class="info-value">{{ dialogconfig.type }}</div>
          </div>
          <div class="info-container">
            <div class="info-title">Student(s)</div>
            <div class="info-value">{{ dialogconfig.NumberOfCargo }}</div>
          </div>
        </div>

        <div class="col-lg-6 pb-0">
          <div class="info-container">
            <div class="info-title">Driver</div>
            <div class="info-value">
              {{ dialogconfig.DriverFullName }}
              <span class="hint">#{{ dialogconfig.DriverId }}</span>
            </div>
          </div>
          <div class="info-container">
            <div class="info-title">Service</div>
            <div class="info-value">{{ dialogconfig.RideServiceType }}</div>
          </div>
          <div class="info-container">
            <div class="info-title">Monitor(s)</div>
            <div class="info-value">{{ dialogconfig.NumberofAttendants }}</div>
          </div>
          <div class="info-container">
            <div class="info-title">Payout</div>
            <div class="info-value">
              ${{ dialogconfig.ExpectedPayout | number : ".2-2" }}
            </div>
          </div>
        </div>
      </div>
      <!-- Invoice Note ends -->
    </div>

    <div class="invoice-preview-card mb-2">
      <!-- Header starts -->
      <div class="card-body invoice-padding pb-0">
        <div class="my-2">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Ride Overview</h5>
              </div>
            </div>
          </div>
          <hr class="mt-0 mb-2" />
        </div>
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <div class="w-100">
            <ul class="timeline">
              <li
                class="timeline-item"
                *ngFor="let item of dialogconfig.waypoints"
              >
                <span class="timeline-time timeline-stop-time">
                  {{ item.ScheduledArrivalTime }}
                </span>
                <span class="badge badge-secondary bg-info Stop-span pt-1 pb-1"
                  >Stop {{ item.waypointCount }}
                </span>
                <span
                  class="timeline-point timeline-point-info timeline-point-indicator"
                ></span>
                <div class="timeline-event">
                  <div class="row">
                    <div class="col-lg-12 pb-0">
                      <p
                        class="Time-line-title text-primary bold mb-0 d-inline"
                      >
                        {{ item.Name }}
                      </p>
                    </div>
                    <div class="col-lg-12 pb-0">
                      <p class="pl-1 Time-line-title Text-secondary">
                        {{ item.FullAddress }}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-lg-12 pb-0">
                      <div class="row">
                        <div
                          class="col-lg-6 pb-0"
                          *ngFor="let Cargo of item.Cargo"
                        >
                          <div class="row">
                            <div class="col-lg-12 pb-0">
                              <p class="Time-line-title text-primary bold mb-0">
                                {{ Cargo.ActionType }}
                              </p>
                            </div>
                            <div class="col-lg-12 pb-0">
                              <p class="pl-1 Time-line-title Text-secondary">
                                {{ Cargo.Name }}
                                <span class="hint">
                                  ({{ Cargo.CargoType }})</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body invoice-padding">
      <div class="my-2">
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <div>
            <div class="logo-wrapper">
              <h5 class="text-primary invoice-logo">Equipment Needed</h5>
            </div>
          </div>
        </div>
        <hr class="m-0" />
      </div>
      <!-- Invoice Note starts -->
      <div
        class="row"
        *ngIf="dialogconfig.RideEquipment.length == 0"
      >
        <div class="col-12 pb-0">
          <div>
            <p class="mb-75 text-secondary w-30 d-inline-block">
              None required.
            </p>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="dialogconfig.RideEquipment.length > 0"
      >
        <div
          class="col-12 pb-0"
          *ngFor="let RideEquipment of dialogconfig.RideEquipment"
        >
          <div>
            <p class="mb-75 text-secondary w-30 d-inline-block">
              {{ RideEquipment.NumberOfEquipment }} x {{ RideEquipment.Name }}
            </p>
          </div>
        </div>
      </div>
      <!-- Invoice Note ends -->
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-info"
        (click)="modal.close('Accept click')"
        rippleEffect
      >
        Close
      </button>
    </div>
  </div>
</ng-template>
