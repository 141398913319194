import { Pipe, PipeTransform } from "@angular/core";
let StringMask = require("string-mask");
@Pipe({ name: "SSNMask" })
export class SSNMask implements PipeTransform {
  transform(value: string) {
    value = value.substring(value.length - 4);
    let formatter = new StringMask("xxx-xx-0000");
    let result = formatter.apply(value);
    return result;
  }
}