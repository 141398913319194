<ul  class="no-style-list" >
<li
  ngbDropdown
  class="nav-item dropdown-chat mr-25"
>
  <a
    class="nav-link"
    ngbDropdownToggle
    id="navbarchatDropdown"
    ><i
      class="ficon"
      data-feather="message-circle"
    ></i
    ><span class="badge badge-pill badge-primary badge-up">0</span></a
  >
</li>
</ul>