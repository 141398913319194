<div
  *ngIf="Config.filterVisability"
  class="form-group"
>
  <!-- Basic Select with Search -->
  <input
    type="number"
    [disabled]="Config.filterDisabled"
    class="form-control"
    [(ngModel)]="Config.filterValue"
    [placeholder]="Config.filterPlaceholder"
  />
  <!--/ Basic Select with Search -->
</div>
