import { Injectable } from "@angular/core";
import { CoreMenu } from "@core/types";
import { AuthenticationService } from "app/auth/service/authentication.service";
import { PermissionsList } from "app/enums/Permissions/PermissionList";

@Injectable({ providedIn: "root" })
export class MenuService {
  public menu: CoreMenu[] = [];
  constructor(public _authenticationService: AuthenticationService) {
    this.menu = [
      {
        id: "dashboard",
        title: "Dashboard",
        translate: "MENU.DASHBOARD.COLLAPSIBLE",
        type: "collapsible",
        // role: ['Admin'], //? To hide collapsible based on user role
        icon: "home",
        //ishidden:haspermission('aview')
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList[PermissionsList.FinancialDashboardView],
          PermissionsList[PermissionsList.PerformaceDashboardView],
        ]),
        badge: {
          title: "",
          translate: "MENU.DASHBOARD",
          classes: "",
        },
        children: [
          {
            id: "Financial",
            title: "Financial",
            translate: "MENU.DASHBOARD.FinancialDashboard",
            type: "item",
            icon: "",
            url: "dashboard/financial",
            hidden: !this._authenticationService.checkaccess(
              PermissionsList[PermissionsList.FinancialDashboardView],
            ),
            permission: PermissionsList[PermissionsList.FinancialDashboardView],
          },
          {
            // If role is not assigned will be display to all
            id: "Performace",
            title: "Performance",
            translate: "MENU.DASHBOARD.PerformaceDashboard",
            type: "item",
            icon: "",
            url: "dashboard/performace",
            hidden: !this._authenticationService.checkaccess(
              PermissionsList[PermissionsList.PerformaceDashboardView],
            ),
            permission:
              PermissionsList[PermissionsList.PerformaceDashboardView],
          },
        ],
      },
      {
        id: "financial",
        title: "Financial",
        translate: "MENU.financial",
        type: "collapsible",
        classes: "financialmenu",
        // role: ['Admin'], //? To hide collapsible based on user role
        icon: "file-text",
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList[PermissionsList.FinancialOverviewAnnualRevenueView],
          PermissionsList[PermissionsList.FinancialOverviewClientInvoicesView],
          PermissionsList[
            PermissionsList.FinancialOverviewPayoutActivityReportView
          ],
          PermissionsList[
            PermissionsList.FinancialOverviewContractorsAnnualReportView
          ],
          PermissionsList[PermissionsList.FinancialOverviewOAIReportView],
        ]),
        badge: {
          title: "",
          translate: "MENU.financial",
          classes: "",
        },
        children: [
          {
            id: "AnnualRevenueReport",
            title: "Annual Revenue Report",
            translate: "MENU.financial.AnnualRevenueReport",
            type: "item",
            icon: "",
            url: "financial/AnnualRevenueReport",
            hidden: !this._authenticationService.checkaccess(
              PermissionsList[
                PermissionsList.FinancialOverviewAnnualRevenueView
              ],
            ),
            permission:
              PermissionsList[
                PermissionsList.FinancialOverviewAnnualRevenueView
              ],
          },
          {
            // If role is not assigned will be display to all
            id: "ClientInvoicesReport",
            title: "Client Invoices Report",
            translate: "MENU.financial.ClientInvoicesReport",
            type: "item",
            icon: "",
            url: "financial/ClientInvoicesReport",
            hidden: !this._authenticationService.checkaccess(
              PermissionsList[
                PermissionsList.FinancialOverviewClientInvoicesView
              ],
            ),
            permission:
              PermissionsList[
                PermissionsList.FinancialOverviewClientInvoicesView
              ],
          },
          {
            // If role is not assigned will be display to all
            id: "PayoutActivityReport",
            title: "Payout Activity Report",
            translate: "MENU.financial.PayoutActivityReport",
            type: "item",
            icon: "",
            url: "financial/PayoutActivityReport",
            hidden: !this._authenticationService.checkaccess(
              PermissionsList[
                PermissionsList.FinancialOverviewPayoutActivityReportView
              ],
            ),
            permission:
              PermissionsList[
                PermissionsList.FinancialOverviewPayoutActivityReportView
              ],
          },
          {
            // If role is not assigned will be display to all
            id: "ContractorsAnnualReport",
            title: "Contractors Annual Report",
            translate: "MENU.financial.ContractorsAnnualReport",
            type: "item",
            icon: "",
            url: "financial/ContractorsAnnualReport",
            hidden: !this._authenticationService.checkaccess(
              PermissionsList[
                PermissionsList.FinancialOverviewContractorsAnnualReportView
              ],
            ),
            permission:
              PermissionsList[
                PermissionsList.FinancialOverviewContractorsAnnualReportView
              ],
          },
          {
            // If role is not assigned will be display to all
            id: "OAIReport",
            title: "OAI Report",
            translate: "MENU.financial.OAIReport",
            type: "item",
            icon: "",
            url: "financial/OAIReport",
            hidden: !this._authenticationService.checkaccess(
              PermissionsList[PermissionsList.FinancialOverviewOAIReportView],
            ),
            permission:
              PermissionsList[PermissionsList.FinancialOverviewOAIReportView],
          },
        ],
      },
      {
        id: "Performance",
        title: "Performance",
        translate: "MENU.Performance",
        type: "collapsible",
        // role: ['Admin'], //? To hide collapsible based on user role
        icon: "pie-chart",
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList[PermissionsList.PerformanceArrivalTimeReportView],
          PermissionsList[PermissionsList.PerformanceIncidentReportView],
        ]),
        badge: {
          title: "",
          translate: "MENU.Performance",
          classes: "",
        },
        children: [
          {
            id: "ArrivalTimeReport",
            title: "Arrival Time Report",
            translate: "MENU.Performance.ArrivalTimeReport",
            type: "item",
            icon: "",
            url: "Performance/ArrivalTimeReport",
            hidden: !this._authenticationService.checkaccess(
              PermissionsList[PermissionsList.PerformanceArrivalTimeReportView],
            ),
            permission:
              PermissionsList[PermissionsList.PerformanceArrivalTimeReportView],
          },
          {
            // If role is not assigned will be display to all
            id: "IncidentReport",
            title: "Incident Report",
            translate: "MENU.Performance.IncidentReport",
            type: "item",
            icon: "",
            url: "Performance/IncidentReport",
            hidden: !this._authenticationService.checkaccess(
              PermissionsList[PermissionsList.PerformanceIncidentReportView],
            ),
            permission:
              PermissionsList[PermissionsList.PerformanceIncidentReportView],
          },
        ],
      },

      {
        id: "Quality",
        title: "Quality",
        translate: "MENU.Quality",
        type: "collapsible",
        // role: ['Admin'], //? To hide collapsible based on user role
        icon: "check-square",
        hidden: !this._authenticationService.checkparantaccess([
          PermissionsList[PermissionsList.QualityOverviewRideQualityReportView],
        ]),
        badge: {
          translate: "MENU.Quality",
          classes: "",
        },
        children: [
          {
            // If role is not assigned will be display to all
            id: "RideQualityReport",
            title: "Ride Quality Report",
            translate: "MENU.Quality.RideQualityReport",
            type: "item",
            icon: "",
            url: "Quality/RideQualityReport",
            hidden: !this._authenticationService.checkaccess(
              PermissionsList[
                PermissionsList.QualityOverviewRideQualityReportView
              ],
            ),
            permission:
              PermissionsList[
                PermissionsList.QualityOverviewRideQualityReportView
              ],
          },
        ],
      },
    ];
  }
}
