import { PermissionsList } from "app/enums/Permissions/PermissionList";
import { Role } from "./role";

export class User {
  id: number;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  full_name: string;
  short_name: string;
  mobile_number: string;
  nick_name: string;
  avatar: string;
  role: Role;
  access_token?: string;
  PortalName: string;
  Permissions: PermissionsList[];
}
