import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IpayoutlineitemConfig } from "app/interfaces/controls/dialogs/IpayoutlineitemConfig";
import { RideStatusTypeEnum } from "../../../../enums/lookups/LookupEnum";

@Component({
  selector: "app-payout-line",
  templateUrl: "./payout-line.component.html",
})
export class PayoutLineComponent  {
  @Input() dialogconfig: IpayoutlineitemConfig;
  @ViewChild("PayoutLine")
  private modalRef: TemplateRef<any>;

  constructor(private modalService: NgbModal) {}


  modalOpen() {
    this.modalService.open(this.modalRef, {
      centered: true,
      size: "xl",
    });
  }
  modalOpenlg() {
    this.modalService.open(this.modalRef, {
      centered: true,
      size: "lg",
    });
  }

  Ride(row) {
      if (row.RideStatusId == RideStatusTypeEnum.InProgress) {
          return '<span class="font-size-12 badge badge-primary min-width-34">In Progress</span>';
      } else if (row.RideStatusId == RideStatusTypeEnum.CanceledDueToHoliday) {
          return '<span class="font-size-12 badge badge-secondary min-width-34">Holiday</span>';
      } else if (row.RideStatusId == RideStatusTypeEnum.CanceledDueToPandemic) {
          return '<span class="font-size-12 badge badge-secondary min-width-34">Pandemic Holiday</span>';
      } else if (row.RideStatusId == RideStatusTypeEnum.Completed) {
          return '<span class="font-size-12 badge badge-success min-width-34">Completed</span>';
      } else if (row.RideStatusId == RideStatusTypeEnum.NoShow) {
          return '<span class="font-size-12 badge badge-success min-width-34">No Show</span>';
      } else if (row.RideStatusId == RideStatusTypeEnum.CanceledLate) {
          return '<span class="font-size-12 badge badge-success min-width-34">Late Cancel</span>';
      } else if (row.RideStatusId == RideStatusTypeEnum.Deleted) {
          return '<span class="font-size-12 badge badge-danger min-width-34">Deleted</span>';
      } else if (row.RideStatusId == RideStatusTypeEnum.None) {
          return '<span class="font-size-12 badge badge-primary min-width-34">Scheduled</span>';
      } else {
          return row.RideStatus;
      }
  }
  RideNotServiced(row) {
    let calculatedTotal =
      parseFloat(row.BaseFare) +
      parseFloat(row.CalculatedAdditionalMilesFee) +
      parseFloat(row.CalculatedTollFee) +
      parseFloat(row.BlueprintAdjustmentAmount) +
      parseFloat(row.PartnerAdjustmentAmount);
    return Number(
      calculatedTotal * (row.PartnerPayoutPercentageAfterWithhold / 100),
    ).toFixed(2);
  }
}
