export const locale = {
  lang: "en",
  data: {
    MENU: {
      DASHBOARD: {
        COLLAPSIBLE: "Dashboards",
        BADGE: "",
        FinancialDashboard: "Financial Dashboard",
        PerformaceDashboard: "Performace Dashboard",
      },
      financial: {
        COLLAPSIBLE: "Financial",
        BADGE: "",
        AnnualRevenueReport: "Annual Revenue Report",
        ClientInvoicesReport: "Client Invoices Report",
        PayoutActivityReport: "Payout Activity Report",
        ContractorsAnnualReport: "Contractors Annual Report",
        OAIReport: "OAI Report",
      },
      Performance: {
        COLLAPSIBLE: "Performance",
        BADGE: "",
        ArrivalTimeReport: "Arrival Time Report",
        IncidentReport: "Incident Report",
      },
      Quality: {
        COLLAPSIBLE: "Quality",
        BADGE: "",
        RideQualityReport: "Ride Quality Report",
      },
      HOME: "Home",
      SAMPLE: "Sample",
    },
  },
};
