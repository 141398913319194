import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreCommonModule } from "@core/common.module";
import { CardSnippetModule } from "@core/components/card-snippet/card-snippet.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { IncidentReportDialogComponent } from "./incident-report-dialog/incident-report-dialog.component";
import { ProfileComponent } from "./profile/profile.component";
import { ProfileService } from "../service/profile.service";
import { PipesModule } from "app/utilities/customPipes/customPipesModule";
import { RideSummaryComponent } from "./ride-summary/ride-summary.component";
import { RideSummaryService } from "../service/rideSummary.service";
import { PayoutLineComponent } from "./payout-line/payout-line.component";
import { PayoutlineitemService } from "../service/payoutlineitem.service";

@NgModule({
  declarations: [
    IncidentReportDialogComponent,
    ProfileComponent,
    RideSummaryComponent,
    PayoutLineComponent,
  ],
  imports: [
    CoreCommonModule,
    CommonModule,
    NgbModule,
    ContentHeaderModule,
    TranslateModule,
    Ng2FlatpickrModule,
    NgSelectModule,
    CardSnippetModule,
    PipesModule,
  ],
  exports: [
    IncidentReportDialogComponent,
    ProfileComponent,
    RideSummaryComponent,
    PayoutLineComponent,
  ],
  providers: [ProfileService, RideSummaryService, PayoutlineitemService],
})
export class DialogsModule {}
