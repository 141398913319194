import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IRideSummaryConfig } from "app/interfaces/controls/dialogs/IRideSummaryConfig";
import { RideStatusTypeEnum } from "../../../../enums/lookups/LookupEnum";

@Component({
  selector: "app-ride-summary",
  templateUrl: "./ride-summary.component.html",
  styleUrls: ["./ride-summary.component.scss"],
})
export class RideSummaryComponent {
  @Input() dialogconfig: IRideSummaryConfig;
  @ViewChild("RideSummary")
  private modalRef: TemplateRef<any>;

  constructor(private modalService: NgbModal) {}



  modalOpen() {
    this.modalService.open(this.modalRef, {
      centered: true,
      size: "xl",
    });
  }
  modalOpenlg() {
    this.modalService.open(this.modalRef, {
      centered: true,
      size: "lg",
    });
  }

  Ride(row) {
    if (row.RideStatusId == RideStatusTypeEnum.CanceledInAdvance) {
      return '<span class="font-size-12 badge badge-secondary min-width-34">Early Cancel</span>';
  } else if (row.RideStatusId == RideStatusTypeEnum.CanceledDueToHoliday) {
      return '<span class="font-size-12 badge badge-secondary min-width-34">Holiday</span>';
  } else if (row.RideStatusId == RideStatusTypeEnum.CanceledDueToPandemic) {
      return '<span class="font-size-12 badge badge-secondary min-width-34">Pandemic Holiday</span>';
  } else if (row.RideStatusId == RideStatusTypeEnum.Completed) {
      return '<span class="font-size-12 badge badge-success min-width-34">Completed</span>';
  } else if (row.RideStatusId == RideStatusTypeEnum.NoShow) {
      return '<span class="font-size-12 badge badge-success min-width-34">No Show</span>';
  } else if (row.RideStatusId == RideStatusTypeEnum.CanceledLate) {
      return '<span class="font-size-12 badge badge-success min-width-34">Late Cancel</span>';
  } else if (row.RideStatusId == RideStatusTypeEnum.InProgress) {
      return '<span class="font-size-12 badge badge-primary min-width-34">In Progress</span>';
  } else if (row.RideStatusId == RideStatusTypeEnum.Deleted) {
      return '<span class="font-size-12 badge badge-danger min-width-34">Deleted</span>';
  } else if (row.RideStatusId == RideStatusTypeEnum.None) {
      return '<span class="font-size-12 badge badge-primary min-width-34">Scheduled</span>';
  } else {
      return row.RideStatus;
  }
  }
}
