import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "poNumber" })
export class PoNumber implements PipeTransform {
  transform(value: string, max: number) {
    if(value==null)
    {
      return '';
    }
    return value.length < max ? this.transform("0" + value, max) : value;
  }
}
