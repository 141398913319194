<div
  *ngIf="Config.filterVisability"
  class="form-group"
>
  <ng-select
    id="weeklyperiod"
    [items]="Config.filterValues"
    [clearable]="Config.filterClear"
    [disabled]="Config.filterDisabled"
    [multiple]="Config.filterMultiple"
    [placeholder]="Config.filterPlaceholder"
    [(ngModel)]="Config.filterValue"
    (change)="onchange()"
    bindLabel="name"
    bindValue="id"
  >
    <ng-template
      ng-option-tmp
      ng-label-tmp
      let-item="item"
    >
      {{ item.name }}
      <br />
      <small class="text-secondary">{{ item.date }}</small>
    </ng-template>
  </ng-select>
</div>
