import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr"; // For auth after login toast

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { DashboardModule } from "app/main/dashboard/dashboard.module";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { FakeDbService } from "@fake-db/fake-db.service";
import { AuthGuard } from "./auth/helpers/auth.guards";
import { JwtInterceptor } from "./auth/helpers/jwt.interceptor";
import { ControlsModule } from "./controls/controls.module";
import { SharedModule } from "./shared/shared.module";

const appRoutes: Routes = [
  {
    path: "pages",
    loadChildren: () =>
      import("./main/pages/pages.module").then(m => m.PagesModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./main/dashboard/dashboard.module").then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "financial",
    loadChildren: () =>
      import("./main/financial/financial.module").then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "Performance",
    loadChildren: () =>
      import("./main/Performance/Performance.module").then(
        m => m.PerformanceModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "Quality",
    loadChildren: () =>
      import("./main/Quality/Quality.module").then(m => m.QualityModule),
    canActivate: [AuthGuard],
  },
  {
    path: "Profile",
    loadChildren: () =>
      import("./main/Profile/Profile.module").then(m => m.ProfileModule),
    canActivate: [AuthGuard],
  },

  {
    path: "",
    redirectTo: "/pages/authentication/login",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/pages/miscellaneous/error", //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled", // Add options right here
      relativeLinkResolution: "legacy",
    }),
    TranslateModule.forRoot(),
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true,
    }),
    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    DashboardModule,
    ControlsModule,
    SharedModule,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
