export class ChatWidgetFakeData {
  public static data = {
    userProfile: {
      id: 11,
      avatar: "assets/images/avatars/8.png",
      fullName: "John Doe",
      role: "admin",
      about:
        "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
      status: "online",
      settings: {
        isTwoStepAuthVerificationEnabled: true,
        isNotificationsOn: false,
      },
    },

    chatUser: {
      id: 1,
      fullName: "Felecia Rower",
      role: "Frontend Developer",
      about:
        "Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing",
      avatar: "assets/images/avatars/1.png",
      status: "offline",
    },

    chats: {
      id: 1,
      userId: 2,
      unseenMsgs: 0,
      chat: [
        {
          message: "Hi",
          time: "Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)",
          senderId: 11,
        },
        {
          message: "Hello. How can I help You?",
          time: "Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)",
          senderId: 2,
        },
        {
          message:
            "Can I get details of my last transaction I made last month?",
          time: "Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)",
          senderId: 11,
        },
        {
          message: "We need to check if we can provide you such information.",
          time: "Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)",
          senderId: 2,
        },
        {
          message: "I will inform you as I get update on this.",
          time: "Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)",
          senderId: 2,
        },
        {
          message: "If it takes long you can mail me at my mail address.",
          time: "dayBeforePreviousDay",
          senderId: 11,
        },
      ],
    },
  };
}
